import React, { useState, useContext, useEffect } from "react";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import BasicContext from "../../../Context/BasicContext";
import rest from "../../../http/axios";

//UI
import InputField from "../../../Components/InputField";
import Slide from "@material-ui/core/Slide";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { HiDocumentAdd } from "react-icons/hi";
import { BsCheck2Circle } from "react-icons/bs";

export default function SettingsView(props) {
  const { theme, columnId, closeTaskModalEvent } = props;
  const { taskId, projectId, deleteTaskFromProject } = useContext(
    ProjectViewContext
  );
  const { socket } = useContext(BasicContext);
  const [showModal, setShowModal] = useState(false);

  const [templateCreated, setTemplateCreated] = useState(false);
  const [templateName, setTemplateName] = useState("");

  const closeSettingsModal = () => {
    setShowModal(false);
    setTemplateCreated(false);
  };

  const deleteTask = () => {
    runBurnAnimation();

    let payload = { projectId: projectId, columnId: columnId, taskId: taskId };
    let timestamp = new Date().getTime();

    socket.emit("delete_task", payload, () => {
      let newTimestamp = new Date().getTime();
      let timeout = 1000 - (newTimestamp - timestamp);
      deleteTaskFromProject(payload);

      setTimeout(() => {
        setShowModal(false);
        closeTaskModalEvent();
      }, timeout);
    });
  };
  const runBurnAnimation = () => {
    for (let i = 0; i < 10; i++) {
      const xPos = Math.random() * 380;
      const animationDelay = Math.random() * 1000;
      const fireEmoji = document.createElement("div");

      fireEmoji.className = "fire-emoji";
      fireEmoji.innerText = "🔥";
      fireEmoji.style.left = `${xPos}px`;
      fireEmoji.addEventListener("animationend", () => {
        fireEmoji.remove();
      });

      document.getElementById("fire-container").appendChild(fireEmoji);

      setTimeout(() => {
        fireEmoji.style.animation = "flyUp 500ms linear infinite";
        fireEmoji.style.opacity = "1";
      }, animationDelay);
    }
  };

  const createTemplate = () => {
    rest
      .post("/add_task_template", {
        taskId: taskId,
        templateName: templateName,
      })
      .then((response) => {
        setTemplateCreated(true);
      });
  };

  useEffect(() => {
    if (showModal == true) {
      rest.get(`/get_template_by_task_id/${taskId}`).then((response) => {
        let rsp = response.data;
        setTemplateCreated(true);
        setTemplateName(rsp.name);
      });
    }
  }, [showModal]);

  return (
    <React.Fragment>
      <div
        className="top-menu-nav min"
        onClick={() => setShowModal(!showModal)}
      >
        <div className={`active-icon ${theme}`} style={{ fontSize: "1.3em" }}>
          <BsThreeDotsVertical />
        </div>
      </div>
      {showModal ? (
        <div className="modal-cover active" onClick={closeSettingsModal}></div>
      ) : null}

      <Slide direction="up" in={showModal} mountOnEnter unmountOnExit>
        <div className="modal-wrapper">
          <div
            className="simple-modal"
            id="fire-container"
            style={{ position: "relative" }}
          >
            <div className="modal-header">
              <h1 className="h1">Настройки задачи</h1>
            </div>

            <div className="modal-content">
              <div className="content-wrapper">
                <h2 className="h2">Создать шаблон</h2>
                <p className="text small">
                  Из этой задачи вы можете сделать шаблон. При его использовании
                  описание, списки и ярлыки будут автоматически скопированы в
                  новую задачу
                </p>
                <div className="">
                  {templateCreated == false ? (
                    <React.Fragment>
                      <InputField
                        label="Название шаблона"
                        defaultValue={templateName}
                        iconObj=<HiDocumentAdd />
                        // theme={theme}
                        onChangeEvent={setTemplateName}
                      />

                      <div className="button-wrapper">
                        <button
                          className="button primary"
                          onClick={createTemplate}
                        >
                          Сохранить шаблон
                        </button>
                      </div>
                    </React.Fragment>
                  ) : (
                    <div className="row">
                      <div className="col min">
                        <BsCheck2Circle
                          className="filled-icon active"
                          style={{ marginRight: "5px" }}
                        />
                      </div>
                      <div className="col vertical-center">
                        <p className="text small">
                          Шаблон <b>"{templateName}"</b> создан и доступен в
                          списке шаблонов. Вы можете использовать его при
                          заведении задачи
                        </p>

                        <button
                          className="button default small"
                          onClick={() => setTemplateCreated(false)}
                        >
                          Редактировать
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="content-wrapper">
                <h2 className="h2">Удалить задачу</h2>
                <p className="text small">
                  Если вы удалите задачу, ее будет невозможно восстановить. Вся
                  история о передвижении этой задачи будет стерта
                </p>
                <div className="button-wrapper left">
                  <button
                    className="button danger outline"
                    onClick={deleteTask}
                  >
                    <MdDelete
                      style={{ fontSize: "1.3em", marginRight: "10px" }}
                    />{" "}
                    Уверен, что хочу удалить задачу
                  </button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button className=" button" onClick={closeSettingsModal}>
                Закрыть
              </button>
            </div>
          </div>
        </div>
      </Slide>
    </React.Fragment>
  );
}
