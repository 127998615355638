import React, { useState, useRef, useEffect, useContext } from "react";
import { format, addDays, differenceInDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import TextareaAutosize from "react-textarea-autosize";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import AppContext from "../../../Context/AppContext";
import BasicContext from "../../../Context/BasicContext";
import rest from "../../../http/axios";
import TemplateItem from "./AddTaskForm/TemplateItem";

import "../../../styles/Loading.css";

//UI
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { MdOutlineArrowDropUp, MdOutlineArrowDropDown } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function AddTaskForm(props) {
  const {
    projectId,
    addTaskToProject,
    showTaskModalAfterCreationEvent,
    showTaskModalEvent,
    loadProject,
  } = useContext(ProjectViewContext);

  const navigate = useNavigate();

  const { currentUser } = useContext(AppContext);
  const { socket } = useContext(BasicContext);

  const { columnId, theme } = props;
  const [showForm, setShowForm] = useState(false);
  const [inputTaskName, setInputTaskName] = useState("");
  const [processing, setProcessing] = useState(false); // говорит о том, идет ли сейчас взаимодействие с сервером
  const [inputDateTo, setInputDateTo] = useState(addDays(new Date(), 14));

  // ШАБЛОНЫ
  const [templates, setTemplates] = useState([]); // шаблоны задач
  const [selectedTemplate, setSelectedTemplate] = useState(null); // выбранный шаблон задачи

  // ПОХОЖИЕ ЗАДАЧИ
  const [similarTasks, setSimilarTasks] = useState([]); // список похожих задач [id, name
  const [timer, setTimer] = useState(null); // таймер для поиска похожих задач

  const [snackbar, setSnackbar] = useState({
    status: false,
    message: "",
    severity: "success",
  });

  const [showAdditionalSettings, setShowAdditionalSettings] = useState(false); // окно с выбором шаблона

  const textareaDescriptionRef = useRef();

  useEffect(() => {
    // Clear the existing timer if the input value changes
    if (timer) clearTimeout(timer);

    // Set a new timer
    const newTimer = setTimeout(() => {
      if (inputTaskName.length > 3) {
        rest
          .get(`/load_similar_tasks/${projectId}/${inputTaskName}`)
          .then((response) => {
            if (response.status == 200) {
              console.log(response.data.similarTasks);
              setSimilarTasks(response.data.similarTasks);
            }
          });
      } else {
        if (similarTasks.length > 0) {
          setSimilarTasks([]);
        }
      }
    }, 700);

    setTimer(newTimer);

    // Clear the timer on component unmount
    return () => clearTimeout(newTimer);
  }, [inputTaskName]);

  useEffect(() => {
    if (showForm === true) {
      textareaDescriptionRef.current.focus();
    }
  }, [showForm]);

  function showAddTaskForm(event) {
    event.preventDefault();
    setShowForm(true);
  }

  function closeAddTaskForm(event) {
    event.preventDefault();
    setShowForm(false);
    closeAdditionalSettings();
    setSimilarTasks([]);
  }

  function addTask(event) {
    if (inputTaskName.length <= 3) {
      showSnackbar("Слишком короткое название", "error");
      return;
    }
    if (inputTaskName.length > 199) {
      let excess = inputTaskName.length - 199;
      showSnackbar(`Название задачи превышено на ${excess} символов`, "error");
      return;
    }

    let taskObj = {
      id: null,
      name: inputTaskName,
      daysTo: differenceInDays(inputDateTo, new Date()) + 1,
      color: "grey",
      budget: 0,
      changeNtfc: 0,
      messsageNtfc: 0,
      countFiles: 0,
      subscribers: [
        {
          userId: currentUser.id,
          userPic: currentUser.user_pic,
          userName: currentUser.name,
        },
      ],
      label: { label_id: 0, label_color: "", label_name: "" },
      activeSessions: [],
    };

    let taskData = {
      projectId: projectId,
      columnId: columnId,
      dateTo: format(inputDateTo, "yyyy-MM-dd"),
      parentTaskId: null,
      taskObj: taskObj,
      templateId: selectedTemplate,
    };

    socket.emit("add_task", taskData, (callback) => {
      // получаем от сервера обратно те же данные, которые отправили, но уже с id задачи
      let newTaskData = callback.taskData;
      addTaskToProject(newTaskData);
      setInputTaskName("");
      closeAddTaskForm(event);
      setProcessing(false);
      if (showTaskModalAfterCreationEvent == true) {
        showTaskModalEvent(newTaskData.taskObj.id);
      }
    });
    setProcessing(true);
  }

  function setFutureDate(event, days) {
    event.preventDefault();
    setInputDateTo(addDays(new Date(), days));
  }

  const openAdditionalSettings = () => {
    rest.get(`/get_task_templates_list/${projectId}`).then((response) => {
      if (response.data.length > 0) {
        let temps = [
          { id: 0, name: "Без шаблона", active: "selected" },
          ...response.data,
        ];
        setTemplates(temps);
      }
    });
    setShowAdditionalSettings(true);
  };

  const closeAdditionalSettings = () => {
    setShowAdditionalSettings(false);
    setSelectedTemplate(null);
    setTemplates([]);
  };

  const changeTemplateRadio = (event) => {
    let templateId = event.target.value;
    let newTemplates = templates.map((item) => {
      if (item.id == templateId) {
        item.active = "selected";
      } else {
        item.active = "";
      }
      return item;
    });
    setTemplates(newTemplates);
    setSelectedTemplate(templateId);
  };

  const removeTemplateItem = (templateId) => {
    rest.post("/remove_task_template", { templateId: templateId }).then(() => {
      showSnackbar("Шаблон удален", "success");
    });

    let newTemplates = templates.filter((item) => item.id != templateId);
    setTemplates(newTemplates);
  };

  function showSnackbar(message, severity) {
    setSnackbar({ status: true, message: message, severity: severity });
  }

  function hideSnackBar() {
    setSnackbar({ status: false, message: "" });
  }
  const loadProjectWithSimilarTasks = () => {
    navigate(
      `/web_app/project/${projectId}?ids=${similarTasks.map((item) => item.id)}`
    );
    loadProject(`ids=${similarTasks.map((item) => item.id)}`);
  };

  return (
    <div>
      {/* ADD TASK BUTTON */}
      {showForm ? (
        <div className={`column-footer-form active`}>
          <div className={`new-project ${theme}`}>
            {/* <div className="row">
              <i className="text small">Поиск похожих задач...</i>
            </div> */}
            {similarTasks.length > 0 ? (
              <div className="helper-container">
                <div className="row">
                  <div className="col min vertical-center">
                    <h3 className="h3">Похожие задачи</h3>
                  </div>
                  <div className="col min vertical-center">
                    <button
                      className="button small"
                      onClick={loadProjectWithSimilarTasks}
                    >
                      Показать на доске
                    </button>
                  </div>
                </div>

                <ul>
                  {similarTasks.map((item) => (
                    <li
                      className={
                        item.distance < 0.15
                          ? `helper-item bold`
                          : `helper-item`
                      }
                      onClick={() => showTaskModalEvent(item.id)}
                    >
                      {item.taskName} : {item.column} ({item.label})
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <div className="wr">
              <TextareaAutosize
                ref={textareaDescriptionRef}
                placeholder="Название задачи"
                className={`w-input textarea`}
                onChange={(event) => {
                  setInputTaskName(event.target.value);
                }}
              ></TextareaAutosize>
            </div>

            <div className="wr">
              <DatePicker
                selected={inputDateTo}
                onChange={(date) => setInputDateTo(date)}
                dateFormat="dd.MM.yyyy"
                className={`w-input input`}
                locale={ru}
                calendarStartDay={1}
              />
            </div>
            <div className="wr">
              <div className="col">
                <button
                  className={`button small`}
                  onClick={(event) => setFutureDate(event, 7)}
                >
                  Через неделю
                </button>
              </div>
              <div className="col">
                <button
                  className={`button small`}
                  onClick={(event) => setFutureDate(event, 14)}
                >
                  Через две недели
                </button>
              </div>
            </div>

            {showAdditionalSettings ? (
              <div style={{ padding: "10px" }}>
                <h3 className="h3">Шаблоны</h3>
                {templates.length == 0 ? (
                  <span className="text small grey">
                    Список шаблонов пока пуст. Чтобы создать шаблон, откройте
                    необходимю задачу, нажмите <BsThreeDotsVertical /> в правом
                    верхнем углу и создайте из нее шаблон
                  </span>
                ) : null}
                <div onChange={changeTemplateRadio}>
                  {templates.map((item) => (
                    <TemplateItem
                      item={item}
                      removeTemlateItemFromList={removeTemplateItem}
                    />
                  ))}
                </div>
              </div>
            ) : null}
            <div className="wr" style={{ paddingTop: "10px" }}>
              <div className="col min">
                <button
                  className={`button`}
                  onClick={() =>
                    showAdditionalSettings
                      ? closeAdditionalSettings()
                      : openAdditionalSettings()
                  }
                >
                  {showAdditionalSettings ? (
                    <MdOutlineArrowDropDown className="icon big" />
                  ) : (
                    <MdOutlineArrowDropUp className="icon big" />
                  )}
                </button>
              </div>
              <div className="col"></div>
              <div className="col min">
                <button className={`button`} onClick={closeAddTaskForm}>
                  Отмена
                </button>
              </div>
              <div className="col min">
                {processing ? (
                  <button className="button primary">
                    <div className="lds-ripple">
                      <div></div>
                      <div></div>
                    </div>
                    Добавляю
                  </button>
                ) : (
                  <button className="button primary " onClick={addTask}>
                    Добавить
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div onClick={showAddTaskForm} className={`column-footer active`}>
          <div className={`column-base-nav ${theme}`}>+ Добавить задачу</div>
        </div>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.status}
        autoHideDuration={4000}
        onClose={hideSnackBar}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          {...props}
          severity={snackbar.severity}
          // onClose={hideSnackBar}
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
