import React, { useState, useContext } from "react";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import BasicContext from "../../../Context/BasicContext";
import rest from "../../../http/axios";
import ColumnStatusView from "./ColumnStatus/ColumnStatus";
// ICONS

import { MdViewWeek } from "react-icons/md";

//UI
import Slide from "@material-ui/core/Slide";
import { BsCheck2Circle } from "react-icons/bs";

export default function TaskColumn(props) {
  const {
    projectId,
    taskId,
    adoptTaskColumnInProject,
    adoptTaskColumnSuccessStatusInProject,
  } = useContext(ProjectViewContext);
  const { socket } = useContext(BasicContext);
  const {
    columnId,
    columnName,
    updateTaskColumn,
    theme,
    columnSuccessStatus,
    updateTaskColumnSuccessStatus,
  } = props;
  const [modalShow, setModalShow] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({
    id: columnId,
    name: columnName,
  });

  function showModal(event) {
    event.preventDefault();
    rest.get(`/load_columns/${projectId}`).then((response) => {
      let resp = response.data;
      let itemsData = resp.map((item) => {
        if (item.id === selectedItem.id) {
          item.active = "selected";
        } else {
          item.active = "";
        }
        return item;
      });
      setItems(itemsData);
      setModalShow(true);
    });
  }

  function closeModal() {
    setModalShow(false);
  }

  function moveTask() {
    if (selectedItem.id !== columnId) {
      let movePayload = {
        projectId: projectId,
        taskId: taskId,
        currentColumnId: columnId,
        targetColumnId: selectedItem.id,
        targetColumnName: selectedItem.name,
        destinationPosition: 0,
      };

      socket.emit("update_task_column", movePayload);
      updateTaskColumn(movePayload);
      adoptTaskColumnInProject(movePayload);
    }
    closeModal();
  }

  function changeTaskColumnSuccessStatus(status) {
    socket.emit(
      "update_task_column_success_status",
      { taskId, status },
      (resp) => {
        if (resp.status == "ok") {
          updateTaskColumnSuccessStatus(status);
          adoptTaskColumnSuccessStatusInProject({
            columnId,
            taskId,
            status,
          });
        }
      }
    );
    closeModal();
  }

  function changeRadio(event) {
    let targetId = parseInt(event.target.id);
    setItems(
      items.map((item) => {
        if (item.id === targetId) {
          item.active = "selected";
        } else {
          item.active = "";
        }
        return item;
      })
    );
    setSelectedItem({
      id: event.target.id,
      name: event.target.value,
    });
  }

  return (
    <div className="horizontal-col min">
      <MdViewWeek
        className={`icon standart ${theme}`}
        style={{ marginRight: "5px" }}
      />
      <span className={`text ${theme}`}>Колонка:&#8194;</span>
      {columnSuccessStatus ? (
        <BsCheck2Circle
          className="filled-icon text-inline active"
          style={{ marginRight: "5px" }}
        />
      ) : null}

      <strong className={`text dark-blue active ${theme}`} onClick={showModal}>
        {columnName}
      </strong>

      {modalShow ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeModal}></div>
          <Slide direction="up" in={modalShow} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Перенести в:</h1>
                </div>
                <div className="modal-content" style={{ overflow: "hidden" }}>
                  <ColumnStatusView
                    columnName={columnName}
                    columnSuccessStatus={columnSuccessStatus}
                    changeTaskColumnSuccessStatus={
                      changeTaskColumnSuccessStatus
                    }
                  />
                </div>
                <div className="modal-content" onChange={changeRadio}>
                  {items.map((item) => (
                    <label
                      className={`select-item ${item.active}`}
                      key={item.id}
                    >
                      <input
                        type="radio"
                        id={item.id}
                        name="moveToColumn"
                        className="hidden-radio"
                        value={item.name}
                      />
                      <span className="w-form-label">{item.name}</span>
                    </label>
                  ))}
                </div>
                <div className="modal-footer">
                  <button className=" button" onClick={closeModal}>
                    Отмена
                  </button>
                  <button
                    type="submit"
                    className=" button primary"
                    onClick={moveTask}
                  >
                    Перенести
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </div>
  );
}
