import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import debug from "debug";
import AppContext from "./Context/AppContext";
import "./styles/index.css";
import "./styles/Icons.css";
import "./styles/Additional.css";
import "./styles/Chart.css";
import "./styles/Components.css";
import "./styles/App.css";

import Login from "./Auth/Login";
import Logout from "./Auth/Logout";
import TokenLogin from "./Auth/TokenLogin";
import AppRouter from "./AppRouter";
// import "./firebase.js";

const socketDebug = debug("socket.io-client");
socketDebug.enabled = true;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authorized: false,
      currentUser: {},
    };
    this.timestamp = Date.now();
  }

  componentWillMount() {
    if (localStorage.getItem("authorized")) {
      let localUserCookie = localStorage.getItem("current_user");
      let localUser = JSON.parse(localUserCookie);

      this.setState({
        currentUser: {
          id: localUser.id,
          name: localUser.name,
          default_name: localUser.default_name,
          user_pic: localUser.user_pic,
          mob_num: localUser.mob_num,
          email: localUser.email,
          registration_date: localUser.registration_date,
          web_notification: localUser.web_notification,
          web_notification_token: localUser.web_notification_token,
          mail_notification: localUser.mail_notification,
        },
        authorized: true,
      });
    }
  }

  loginUser = (profile) => {
    this.setState({
      authorized: true,
      currentUser: profile,
    });
    localStorage.setItem("authorized", true);
    localStorage.setItem("current_user", JSON.stringify(profile));
  };

  logoutUser = () => {
    localStorage.removeItem("authorized");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("current_user");
    this.setState({ authorized: false, currentUser: {} });
  };

  udpateUserProfileHandler = (
    name_param = false,
    mob_num_param = false,
    user_pic_param = false,
    web_notification_param = null,
    mail_notification_param = null,
    web_token_param = false
  ) => {
    let name = name_param == false ? this.state.currentUser.name : name_param;
    let mob_num =
      mob_num_param == false ? this.state.currentUser.mob_num : mob_num_param;
    let user_pic =
      user_pic_param == false
        ? this.state.currentUser.user_pic
        : user_pic_param;
    let web_notification_token =
      web_token_param == false
        ? this.state.currentUser.web_notification_token
        : web_token_param;
    let web_notification =
      web_notification_param == null
        ? this.state.currentUser.web_notification
        : web_notification_param;
    let mail_notification =
      mail_notification_param == null
        ? this.state.currentUser.mail_notification
        : mail_notification_param;

    this.setState({
      currentUser: {
        id: this.state.currentUser.id,
        name: name,
        default_name: this.state.currentUser.default_name,
        user_pic: user_pic,
        mob_num: mob_num,
        email: this.state.currentUser.email,
        registration_date: this.state.currentUser.registration_date,
        web_notification: web_notification,
        web_notification_token: web_notification_token,
        mail_notification: mail_notification,
      },
    });

    localStorage.setItem(
      "current_user",
      JSON.stringify({
        id: this.state.currentUser.id,
        name: name,
        default_name: this.state.currentUser.default_name,
        user_pic: user_pic,
        mob_num: mob_num,
        email: this.state.currentUser.email,
        registration_date: this.state.currentUser.registration_date,
        web_notification: web_notification,
        web_notification_token: web_notification_token,
        mail_notification: mail_notification,
      })
    );
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          currentUser: this.state.currentUser,
          authorized: this.state.authorized,

          updateUserProfileEvent: this.udpateUserProfileHandler,
          loginUserEvent: this.loginUser,
          logoutUserEvent: this.logoutUser,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path={`/`} element={<Login />} exact={true} />
            <Route
              path={`/login`}
              element={<Login authorized={this.state.authorized} />}
            />

            <Route path={`/logout`} element={<Logout />} />
            <Route path={`/token_auth/:token`} element={<TokenLogin />} />

            <Route
              path="/web_app/*"
              element={<AppRouter authorized={this.state.authorized} />}
            />

            <Route
              render={() => (
                <React.Fragment>
                  <h1>Branzy page is not found. Sorry</h1>
                  <h1>404 </h1>
                </React.Fragment>
              )}
            />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    );
  }
}

export default App;
