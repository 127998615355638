import React, { useState, useEffect, useRef, useContext } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Card from "./Card/CardView";
import AddTaskForm from "./AddTaskForm";
import rest from "../../../http/axios";
import ProjectViewContext from "../../../Context/ProjectViewContext";

// ICONS
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export default function Column(props) {
  const { projectId, column, urlFilter, theme } = props;
  const { adoptColumnNameInProject } = useContext(ProjectViewContext);

  const [showHeaderForm, setShowHeaderForm] = useState(false); // отображение формы переименования колонки
  const [inputColumnName, setInputColumnName] = useState(""); // название колонки, используемое в форме переименования
  const [enableAdditionalLoad, setEnableAdditionalLoad] = useState(
    column.tasks.length > 9
  ); // флаг возможности догрузки данных в колонке
  const [enableLoadOnScroll, setEnableLoadOnScroll] = useState(true); //
  const [currentPage, setCurrentPage] = useState(1);
  const [scrollSuccessfullyTriggerd, setScrollSuccessfullyTriggerd] = useState(
    false
  );

  //   const [targetSettingsButton, setTargetSettingsButton] = useState(null);

  const columnNameRef = useRef();
  const columnBodyRef = useRef();

  //   при изменении количества задач в колонке, проверяем, нужно ли отображать кнопку "Загрузить еще"
  useEffect(() => {
    if (column.tasks.length > 9) {
      setEnableAdditionalLoad(true);
    } else {
      setEnableAdditionalLoad(false);
    }
  }, [column]);

  //  при изменении фильтра, сбрасываем текущую страницу и флаг возможности догрузки данных
  useEffect(() => {
    setCurrentPage(1);
  }, [urlFilter]);

  //   делаем активным поле для ввода, если открыта форма переименования колонки
  useEffect(() => {
    if (showHeaderForm) {
      columnNameRef.current.focus();
    }
  }, [showHeaderForm]);

  const loadMoreTasks = () => {
    setEnableLoadOnScroll(false);
    rest
      .get(
        `/load_column_page/${projectId}/${column.column_id}/${currentPage}?${urlFilter}`
      )
      .then((response) => {
        const rsp = response.data.result;
        if (rsp.length > 0) {
          props.updateColumnDataEvent(column.column_id, rsp);
        }
        if (rsp.length > 9) {
          setEnableLoadOnScroll(true);
          setCurrentPage(currentPage + 1);
        } else {
          setEnableAdditionalLoad(false);
        }
      });
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = columnBodyRef.current;

    if (scrollTop + clientHeight + 250 > scrollHeight) {
      if (enableAdditionalLoad === false || enableLoadOnScroll === false) {
        return;
      }
      loadMoreTasks();
      setScrollSuccessfullyTriggerd(true);
    }
  };

  const openColumnNameForm = () => {
    if (props.userRole !== "admin") {
      return;
    }
    setShowHeaderForm(true);
    setInputColumnName(column.name);
  };

  const closeColumnNameForm = () => {
    rest
      .post("/update_column_name", {
        projectId: projectId,
        columnId: column.column_id,
        name: inputColumnName,
      })
      .then(() => {
        setShowHeaderForm(false);
        adoptColumnNameInProject(column.column_id, inputColumnName);
      });
  };

  const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];
    return (inst) => {
      for (const ref of filteredRefs) {
        if (typeof ref === "function") {
          ref(inst);
        } else if (ref) {
          ref.current = inst;
        }
      }
    };
  };

  return (
    <Draggable
      draggableId={`col${column.column_id.toString()}`}
      index={props.index}
    >
      {(provided) => (
        <div
          className={`column ${theme}`}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className="column-header" {...provided.dragHandleProps}>
            {showHeaderForm ? (
              <div className="horizontal-form-container">
                <input
                  ref={columnNameRef}
                  className={`input w-input ${theme}`}
                  value={inputColumnName}
                  onChange={(event) => setInputColumnName(event.target.value)}
                  onBlur={closeColumnNameForm}
                />
                <button
                  type="submit"
                  className="button  primary small"
                  onClick={closeColumnNameForm}
                >
                  <ArrowForwardIosIcon fontSize="small" />
                </button>
              </div>
            ) : (
              <div className="column-header-wrapper">
                <div className="col">
                  <div
                    className={`column-base-nav ${theme}`}
                    onClick={openColumnNameForm}
                  >
                    {column.name} - {column.numberOfTasks}
                  </div>
                </div>
                {props.userRole == "admin" ? (
                  <div className="col min">
                    <IconButton
                      aria-controls="settings"
                      aria-haspopup="true"
                      onClick={() =>
                        props.showColumnSettingsModal(column.column_id)
                      }
                      className={`icon standart ${theme}`}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                ) : null}
              </div>
            )}
          </div>

          <Droppable droppableId={column.column_id.toString()} type="task">
            {(provided) => (
              <div
                className={`column-body ${theme}`}
                {...provided.droppableProps}
                ref={mergeRefs(provided.innerRef, columnBodyRef)}
                onScroll={handleScroll}
              >
                {column.tasks.map((task, index) => (
                  <Card
                    projectId={projectId}
                    task={task}
                    index={index}
                    key={`task_${task.id}`}
                    columnName={column.name}
                    showDaysTo={column.showDateTo}
                    showDateOfUpdate={column.showDateOfUpdate}
                    showUsers={column.showUsers}
                    theme={theme}
                  />
                ))}
                {enableAdditionalLoad && !scrollSuccessfullyTriggerd ? (
                  <div className="button-wrapper center">
                    <button
                      className="button primary outline"
                      onClick={loadMoreTasks}
                    >
                      Загрузить еще
                    </button>
                  </div>
                ) : null}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {props.userRole === "admin" ? (
            <AddTaskForm columnId={column.column_id} theme={theme} />
          ) : null}
        </div>
      )}
    </Draggable>
  );
}
