import React from "react";

export default function TaskNameReadOnly(props) {
  const { theme } = props;
  return (
    <div className="row">
      <h1 className={`h1 pre-line ${theme}`}>{props.name}</h1>
    </div>
  );
}
