import React, { useState, useEffect, useContext } from "react";
import Slide from "@material-ui/core/Slide";

export default function VersionSelector(props) {
  const {
    maxVersion,
    version,
    createNewDescriptionVersion,
    loadDescriptionVersion,
    compareDescriptionVersions,
    theme,
  } = props;
  const [modalShow, setModalShow] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    let versionItems = [];
    for (var i = 0; i < maxVersion; i++) {
      versionItems.unshift({
        id: i + 1,
        name: "Версия " + (i + 1),
        active: "",
      });
    }
    // set last item as active
    if (versionItems.length > 0) {
      versionItems[0].active = "selected";
      setItems(versionItems);
    }
  }, [maxVersion]);

  const changeRadio = (event) => {
    let targetId = parseInt(event.target.id);
    setItems(
      items.map((item) => {
        if (item.id === targetId) {
          item.active = "selected";
        } else {
          item.active = "";
        }
        return item;
      })
    );
  };

  const chooseVersion = () => {
    let selectedVersion = items.find((item) => item.active === "selected");
    loadDescriptionVersion(selectedVersion.id);
    closeModal();
  };

  const showModal = () => {
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const createNewVersionInList = () => {
    let newVersion = version + 1;
    let currentItems = items.map((item) => {
      item.active = "";
      return item;
    });

    setItems([
      {
        id: newVersion,
        name: "Версия " + newVersion,
        active: "selected",
      },
      ...currentItems,
    ]);
    createNewDescriptionVersion(newVersion);
    closeModal();
  };

  return (
    <React.Fragment>
      <div className="col min vertical-center" onClick={() => showModal()}>
        <strong className={`text dark-blue active ${theme}`}>
          Версия {version}
        </strong>
      </div>
      {modalShow ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeModal}></div>
          <Slide direction="up" in={modalShow} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Выберите версию:</h1>
                </div>
                <div className="modal-content" onChange={changeRadio}>
                  {items.map((item) => (
                    <label
                      className={`select-item ${item.active}`}
                      key={item.id}
                    >
                      <div className="col">
                        <input
                          type="radio"
                          id={item.id}
                          name="moveToColumn"
                          className="hidden-radio"
                          value={item.name}
                        />
                        <span
                          // htmlFor="Radio1"
                          className="w-form-label"
                        >
                          {item.name}
                        </span>
                      </div>

                      <div className="col min">
                        <button
                          className="button small"
                          onClick={() => {
                            console.log("comparing with version: ", item.id);
                            compareDescriptionVersions(item.id);
                            closeModal();
                          }}
                        >
                          Сравнить
                        </button>
                      </div>
                    </label>
                  ))}
                  <div className="button-wrapper right">
                    <button
                      className="button sky outline small"
                      onClick={() => {
                        createNewVersionInList();
                      }}
                    >
                      Добавить новую версию
                    </button>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className=" button" onClick={closeModal}>
                    Отмена
                  </button>
                  <button
                    type="submit"
                    className=" button primary"
                    onClick={chooseVersion}
                  >
                    Выбрать
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
