import React, { useState, useEffect, useContext, useRef } from "react";
import rest from "../../../http/axios";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import AppContext from "../../../Context/AppContext";
import BasicContext from "../../../Context/BasicContext";
import TextareaAutosize from "react-textarea-autosize";
import DatePicker from "react-datepicker";
import { format, addDays, differenceInDays } from "date-fns";
import ru from "date-fns/locale/ru";

//ICONS
import LinkIcon from "@material-ui/icons/Link";

export default function AddLinkedTaskFrom(props) {
  const {
    parentTaskId,
    setShowModalEvent,
    addLinkedTaskToTaskState,
    showSnackBarEvent,
  } = props;

  const { projectId, addTaskToProject } = useContext(ProjectViewContext);
  const { socket } = useContext(BasicContext);
  const { currentUser } = useContext(AppContext);
  const [showForm, setShowForm] = useState(false); // false - привязка по ссылке, true - форма созднания новой задачи
  const [inputDateTo, setInputDateTo] = useState(addDays(new Date(), 14));
  const [inputTaskName, setInputTaskName] = useState("");
  const [taskLink, setTaskLink] = useState("");
  const [step, setStep] = useState("blank");
  const [columns, setColumns] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState({ id: 0, name: "" });
  const linkInputRef = useRef();

  useEffect(() => {
    linkInputRef.current.focus();
    rest.get(`/load_columns/${projectId}`).then((response) => {
      let resp = response.data;
      setColumns(resp);
    });
  }, []);

  function handleSaveButton() {
    if (step == "blank") {
      if (taskLink !== "") {
        addLinkBetweenTasks(taskLink);
        setShowModalEvent(false);
      } else if (inputTaskName !== "") {
        setStep("selectColumn");
      }
    } else if (step == "selectColumn") {
      addLinkedTask();
      setInputTaskName("");
      setShowModalEvent(false);
    }
  }

  function addLinkBetweenTasks(taskLink) {
    const urlParams = taskLink.split("/");
    const domain = urlParams[2];
    if (["branzy.club", "localhost:3000"].includes(domain)) {
      const linkProjectId = urlParams[5];
      const linkTaskId = urlParams[7];

      socket.emit(
        "add_link_between_tasks",
        {
          projectId: projectId,
          masterTaskId: parentTaskId,
          slaveTaskId: linkTaskId,
        },
        (callback) => {
          if (callback == undefined) return;
          if (callback.status == "ok") {
            console.log(callback.data);
            addLinkedTaskToTaskState(callback.data);
            showSnackBarEvent("Задача привязана", "success");
          } else if (callback.status == "error") {
            if (callback.code == "409.1") {
              showSnackBarEvent(
                "Вы не можете привязать задачу саму к себе",
                "warning"
              );
            } else if (callback.code == "409.2") {
              showSnackBarEvent("Задача уже привязана", "warning");
            } else {
              showSnackBarEvent("Не удалось привязать задачу", "error");
            }
          }
        }
      );
    }
  }

  function addLinkedTask() {
    if (inputTaskName == "") {
      return;
    }
    let taskObj = {
      id: null,
      name: inputTaskName,
      daysTo: differenceInDays(inputDateTo, new Date()) + 1,
      color: "grey",
      budget: 0,
      changeNtfc: 0,
      messsageNtfc: 0,
      countFiles: 0,
      subscribers: [
        {
          userId: currentUser.id,
          userPic: currentUser.user_pic,
          userName: currentUser.name,
        },
      ],
      label: { label_id: 0, label_color: "", label_name: "" },
      activeSessions: [],
    };

    let taskData = {
      projectId: projectId,
      columnId: selectedColumn.id,
      dateTo: format(inputDateTo, "yyyy-MM-dd"),
      parentTaskId: parentTaskId,
      taskObj: taskObj,
    };

    socket.emit("add_task", taskData, (callback) => {
      // получаем от сервера обратно те же данные, которые отправили, но уже с id задачи
      let newTaskData = callback.taskData;
      // обогащаем объект задачи данными о колонке, для отображения ее на карточке задачи
      newTaskData.taskObj.columnName = selectedColumn.name;

      addLinkedTaskToTaskState(newTaskData);
      addTaskToProject(newTaskData);
    });
  }

  function changeRadio(event) {
    let targetId = parseInt(event.target.id);

    setColumns(
      columns.map((item) => {
        if (item.id === targetId) {
          item.active = "selected";
        } else {
          item.active = "";
        }
        return item;
      })
    );

    setSelectedColumn({
      id: parseInt(event.target.id),
      name: event.target.value,
    });
  }

  return (
    <React.Fragment>
      <div
        className="modal-cover active"
        onClick={() => setShowModalEvent(false)}
      ></div>
      <div className="modal-wrapper">
        <div className="simple-modal">
          <div className="modal-header">
            <h1 className="h1">Привязать задачу</h1>
          </div>

          {step == "blank" ? (
            <div className="modal-content">
              {showForm ? (
                <React.Fragment>
                  <div className="wr">
                    <TextareaAutosize
                      placeholder="Описание новой задачи"
                      className="textarea w-input"
                      onChange={(event) => setInputTaskName(event.target.value)}
                      value={inputTaskName}
                    ></TextareaAutosize>
                  </div>

                  <div className="wr">
                    <DatePicker
                      selected={inputDateTo}
                      onChange={(date) => setInputDateTo(date)}
                      dateFormat="dd.MM.yyyy"
                      className="w-input input"
                      locale={ru}
                      calendarStartDay={1}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <div className="wr">
                  <div className="input-wrapper">
                    <input
                      type="text"
                      ref={linkInputRef}
                      className="input w-input"
                      placeholder="Ссылка на задачу на доске"
                      value={taskLink}
                      onChange={(event) => setTaskLink(event.target.value)}
                    />
                    <div className="input-icon-wrapper">
                      <LinkIcon />
                    </div>
                  </div>
                </div>
              )}

              {/* РАЗДЕЛИТЕЛЬ */}
              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col min vertical-center">
                  <span className="text small">Или</span>
                </div>
                <div className="col min">
                  {showForm ? (
                    <button
                      className=" button small sky outline"
                      onClick={() => setShowForm(false)}
                    >
                      Привязать задачу по ссылке
                    </button>
                  ) : (
                    <button
                      className=" button small sky outline"
                      onClick={() => setShowForm(true)}
                    >
                      Создать новую задачу и связать
                    </button>
                  )}
                </div>
              </div>

              {/* СОЗДАНИЕ НОВОЙ ЗАДАЧИ */}
            </div>
          ) : null}

          {step == "selectColumn" ? (
            <div className="modal-content" onChange={changeRadio}>
              {columns.map((item) => (
                <label className={`select-item ${item.active}`} key={item.id}>
                  <input
                    type="radio"
                    id={item.id}
                    name="moveToColumn"
                    className="hidden-radio"
                    value={item.name}
                  />
                  <span
                    // htmlFor="Radio1"
                    className="w-form-label"
                  >
                    {item.name}
                  </span>
                </label>
              ))}
            </div>
          ) : null}

          <div className="modal-footer">
            <button
              className=" button"
              onClick={() => setShowModalEvent(false)}
            >
              Закрыть
            </button>
            <button
              className="button  primary"
              onClick={() => {
                handleSaveButton();
              }}
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
