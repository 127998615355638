import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AppContext from "../../../Context/AppContext";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import UserFilter from "./Search/UserFilter";
import LabelFilter from "./Search/LabelFilter";
import ActiveFilter from "./Search/ActiveFilter";
import OverdueFilter from "./Search/OverdueFilter";
import rest from "../../../http/axios";
import ActiveUsers from "./Search/ActiveUsers";
import Changes from "./Changes/Changes";
import ProjectName from "./ProjectName";

// ELEMENTS
import Switch from "@material-ui/core/Switch";

// ICONS
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { BsGrid1X2 } from "react-icons/bs";
// import { BiFilterAlt } from "react-icons/bi";
import PinnedFilter from "./Search/PinnedFilter";

export default function TopMenu(props) {
  const navigate = useNavigate();

  const { currentUser } = useContext(AppContext);
  const { showTaskModalEvent } = useContext(ProjectViewContext);
  const searchRef = useRef(null);

  const {
    projectId,
    projectName,
    projectPic,
    loadData,
    userRole,
    routeToRedirect,
    theme,
    showUnsetFilterButton,
    activeSessions,
  } = props;

  // показать/не показать меню Поиска
  const [showSearchSettings, setShowSearchSettings] = useState(false);
  // управляет классом active, который включает и выключает мобильное меню, которое по умолчанию невидимое,
  // чтобы строка поиска не наезжала на название проекта
  const [activeMobileMenu, setActiveMobileMenu] = useState("");

  // управление состоянием переключателя "только мои задачи"
  const [filterMyTasks, setFilterMyTasks] = useState(false);

  // доступные пользователи для фильтрации
  const [usersInfo, setUsersInfo] = useState([]);
  // список пользователей после фильтрации
  const [usersFiltered, setUsersFiltered] = useState([]);

  // доступные ярлыки для фильтрации
  const [labelsInfo, setLabelsInfo] = useState([]);
  // список ярлыков после фильтрации
  const [labelsFiltered, setLabelsFiltered] = useState([]);
  // указывает на то, включаен ли поиск по chromaDB
  const [smartSearch, setSmartSearch] = useState(false);

  // default значения для переменной фильтров
  const defaultUrlFilter = {
    // данные, для удобного обращения
    data: {
      user_ids: [], // перечень пользователей
      text: "", // поиск по заголовку и описанию
      active: false, // только задачи с активными изменениями
      pinned: false, // только закрепленные задачи
      labels: [],
      overdue: false, // только просроченные задачи
    },
    // данные, собранные для объединения в строку
    arr: [
      "user_ids=",
      "text=",
      "active=false",
      "labels=",
      "pinned=false",
      "overdue=false",
    ],
    // финальный фильтр в виде строки
    str: "",
  };

  // переменная, содержащаа внутри себя все выбранные фильтры
  const [urlFilter, setUrlFilter] = useState(defaultUrlFilter);

  // фильтр "Без лейблов" - выбирает все карточки, которые не протегированы
  const noLabelsFilter = { id: 0, name: "Без ярлыков", color: "black" };

  // переменная для фильтрации тегов в поиске
  const [filterTagsValue, setFilterTagsValue] = useState("");

  // таймер для поиска похожих задач
  const [timer, setTimer] = useState(null);
  const [similarTasks, setSimilarTasks] = useState([]);

  const modifyFilterSet = (action, filter, data, dataDescription = "") => {
    let newUrlFilter = { ...urlFilter };

    if (filter == "labels") {
      if (action == "add") {
        newUrlFilter.data.labels.push(data);
        newUrlFilter.arr[3] = "labels=" + newUrlFilter.data.labels.join(",");
      }
      if (action == "del") {
        newUrlFilter.data.labels = newUrlFilter.data.labels.filter(
          (id) => id != data
        );
        newUrlFilter.arr[3] = "labels=" + newUrlFilter.data.labels.join(",");
      }
      // когда пользователь вбил название в поиск и нажал чекбокс, то нужно поиск сбросить, если он нашел то, что нужно
      if (dataDescription != "") {
        if (
          dataDescription
            .toLowerCase()
            .includes(urlFilter.data.text.toLowerCase())
        ) {
          modifyFilterSet("set", "text", "");
        }
      }
    }

    if (filter == "user_ids") {
      if (action == "add") {
        newUrlFilter.data.user_ids.push(data);

        newUrlFilter.arr[0] =
          "user_ids=" + newUrlFilter.data.user_ids.join(",");
      }
      if (action == "del") {
        newUrlFilter.data.user_ids = newUrlFilter.data.user_ids.filter(
          (id) => id != data
        );

        newUrlFilter.arr[0] =
          "user_ids=" + newUrlFilter.data.user_ids.join(",");
      }

      // когда пользователь вбил название в поиск и нажал чекбокс, то нужно поиск сбросить, если он нашел то, что нужно
      if (dataDescription != "") {
        if (
          dataDescription
            .toLowerCase()
            .includes(urlFilter.data.text.toLowerCase())
        ) {
          modifyFilterSet("set", "text", "");
        }
      }
    }

    if (filter == "text") {
      let smartSearch = /^[а-яА-Яa-zA-Z]+(\s+[а-яА-Яa-zA-Z]+)+$/.test(
        data.trim()
      );
      newUrlFilter.data.text = data;
      if (smartSearch) {
        newUrlFilter.arr[1] = "text=" + data.replace(/[#?&]/g, "") + "*";
        setSmartSearch(true);
      } else {
        newUrlFilter.arr[1] = "text=" + data.replace(/[#?&]/g, "");
        setSmartSearch(false);
      }
    }
    if (filter == "active") {
      newUrlFilter.data.active = data;
      newUrlFilter.arr[2] = "active=" + data;
    }
    if (filter == "pinned") {
      newUrlFilter.data.pinned = data;
      newUrlFilter.arr[4] = "pinned=" + data;
    }
    if (filter == "overdue") {
      newUrlFilter.data.overdue = data;
      newUrlFilter.arr[5] = "overdue=" + data;
    }

    newUrlFilter.str = newUrlFilter.arr.join("&");
    setUrlFilter(newUrlFilter);
  };

  // открывает дополнительное меню при клике на поиск
  const openSettings = () => {
    setShowSearchSettings(true);
    setActiveMobileMenu("active");

    rest.get(`/load_filter_options/${projectId}`).then((response) => {
      const rsp = response.data;
      setFilterMyTasks(rsp.filterMyTasks);
      setUsersInfo(rsp.usersInfo);
      setUsersFiltered(rsp.usersInfo);

      let labelsInfo = rsp.labelsInfo;
      labelsInfo.push(noLabelsFilter);
      setLabelsInfo(labelsInfo);
      setLabelsFiltered(labelsInfo);
    });

    setTimeout(() => {
      searchRef.current.focus();
    }, 200);
  };

  // закрывает дополнительное меню
  const closeSettings = () => {
    setShowSearchSettings(false);
    setActiveMobileMenu("");
    setSmartSearch(false);
    setSimilarTasks([]);
  };

  // управление переключателем "Только мои задачи"
  const toggleFilterMyTasks = (event) => {
    // получаем целевое состояние фильтра из переключателя
    const filterStatus = event.target.checked;
    setFilterMyTasks(filterStatus);

    rest
      .post("/save_my_tasks_filter", {
        projectId: projectId,
        filterStatus: filterStatus,
      })
      .then((response) => {
        loadData("", false);
      });
  };

  // Снимает все наложенные фильтры
  function unsetFilter(event) {
    setUrlFilter(defaultUrlFilter);

    if (routeToRedirect == "desk") {
      loadData("");
    } else if (routeToRedirect == "histogram") {
      loadData("", false);
    }
  }

  function runSearch(event) {
    event.preventDefault();

    if (routeToRedirect == "desk") {
      navigate(`/web_app/project/${projectId}?${urlFilter.str}`, {
        replace: true,
      });
      // loadData, который используется в этой части идет из компонента Desk
      // во втором параметре передается softLoad=false, который избегает локальный кеш
      loadData(urlFilter.str, false);
    } else if (routeToRedirect == "histogram") {
      navigate(`/web_app/project/${projectId}/histogram?${urlFilter.str}`, {
        replace: true,
      });
      // loadData, который используется в этой части идет из компонента Horizontal
      // во втором параматре передается usePageNumForRequest, чтобы при поиске закгружать всегда с первой страницы
      loadData(urlFilter.str, false);
    }

    closeSettings();
  }

  useEffect(() => {
    // Значение при загрузке элемента null, чтобы не накладывать логику при первичной загрузке страницы
    if (filterTagsValue == "") return;
    if (timer) clearTimeout(timer);

    const newTimer = setTimeout(() => {
      // ищу похожие задачи
      if (filterTagsValue.length == 0) setSimilarTasks([]);
      if (smartSearch) {
        if (filterTagsValue.length > 3) {
          rest
            .get(`/load_similar_tasks/${projectId}/${filterTagsValue}`)
            .then((response) => {
              if (response.status == 200) {
                setSimilarTasks(response.data.similarTasks);
              }
            });
        } else {
          if (similarTasks.length > 0) {
            setSimilarTasks([]);
          }
        }
      } else {
        rest
          .get(`/quick_search/${projectId}/${filterTagsValue}`)
          .then((response) => {
            if (response.status == 200) {
              setSimilarTasks(response.data.tasks);
            }
          });
      }

      // Фильруем пользователей и ярлыки
      var ui = usersInfo.filter((item) => {
        if (item.name.toLowerCase().includes(filterTagsValue.toLowerCase())) {
          return item;
        }
      });

      var li = labelsInfo.filter((item) => {
        if (item.name.toLowerCase().includes(filterTagsValue.toLowerCase())) {
          return item;
        }
      });

      if (ui.length + li.length > 0) {
        setUsersFiltered(ui);
        setLabelsFiltered(li);
      } else {
        setUsersFiltered(usersInfo);
        setLabelsFiltered(labelsInfo);
      }
    }, 700);

    setTimer(newTimer);
    return () => clearTimeout(newTimer);
  }, [filterTagsValue]);

  return (
    <div className={`top-menu ${theme}`}>
      <Link to="/web_app" className="top-menu-nav min mobile-only">
        <ArrowBackIosIcon className={`icon ${theme}`} />
      </Link>
      <ProjectName
        projectName={projectName}
        projectPic={projectPic}
        userRole={userRole}
        projectId={projectId}
        theme={theme}
      />

      {userRole === "admin" || userRole === "viewer" ? (
        <React.Fragment>
          {/* ПОИСК */}
          <div
            className={`top-menu-nav center mobile-adoption ${activeMobileMenu}`}
          >
            {showSearchSettings == true ? (
              <div
                className={`search-cover active ${theme}`}
                onClick={closeSettings}
              />
            ) : null}

            <form
              className="search-form w-form"
              method="GET"
              onSubmit={(event) => runSearch(event)}
              target="#"
              // action={`/project/${projectId}?${urlFilter.str}`}
            >
              <div className="input-wrapper" style={{ margin: "0" }}>
                <input
                  type="text"
                  className={`input search w-input`}
                  ref={searchRef}
                  placeholder="Поиск по проекту"
                  value={urlFilter.data.text}
                  onClick={openSettings}
                  onChange={(event) => {
                    modifyFilterSet("set", "text", event.target.value);
                    setFilterTagsValue(event.target.value);
                  }}
                  aria-controls="settings"
                  aria-haspopup="true"
                />
                <div className="input-icon-wrapper">
                  {smartSearch ? <b className="text">AI</b> : <SearchIcon />}
                </div>
              </div>

              {showSearchSettings == true ? (
                // Меню-помощник ПОИСКА
                <div className={`search-settings-container ${theme}`}>
                  <div className="search-settings-body">
                    {similarTasks.length > 0 ? (
                      <React.Fragment>
                        <div className="helper-container">
                          <h2 className="h2">Быстрый поиск</h2>
                          <ul>
                            {similarTasks.map((item, key) => (
                              <li
                                className="helper-item big"
                                key={key}
                                onClick={() => {
                                  showTaskModalEvent(item.id);
                                }}
                              >
                                {item.taskName} : {item.column}{" "}
                                {item.label ? `(${item.label})` : null}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </React.Fragment>
                    ) : null}
                    {/* Раздел активных задач */}
                    {filterTagsValue.length == 0 ? (
                      <React.Fragment>
                        <div className="menu-list">
                          <h2 className="h2">Задачи</h2>
                          <PinnedFilter modifyFilterSet={modifyFilterSet} />
                          <ActiveFilter modifyFilterSet={modifyFilterSet} />
                          <OverdueFilter modifyFilterSet={modifyFilterSet} />
                        </div>
                      </React.Fragment>
                    ) : null}
                    {/* Раздел ярлыков */}

                    <ul role="list" className="menu-list">
                      <h2 className="h2">Ярлыки</h2>
                      {labelsFiltered.map((label, index) => (
                        <LabelFilter
                          label={label}
                          modifyFilterSet={modifyFilterSet}
                          key={label.id}
                          checked={
                            urlFilter.data.labels.includes(label.id)
                              ? true
                              : false
                          }
                        />
                      ))}
                    </ul>
                    {/* Раздел пользователей */}

                    <ul role="list" className="menu-list">
                      <h2 className="h2">Участники</h2>
                      {usersFiltered.map((user, index) => (
                        <UserFilter
                          user={user}
                          modifyFilterSet={modifyFilterSet}
                          key={index}
                          checked={
                            urlFilter.data.user_ids.includes(user.id)
                              ? true
                              : false
                          }
                        />
                      ))}
                    </ul>
                  </div>
                  <div className="search-settings-footer">
                    <button
                      type="button"
                      className={`button ${theme}`}
                      onClick={closeSettings}
                    >
                      Отмена
                    </button>
                    <button
                      type="submit"
                      // onClick={runSearch}
                      className="button primary "
                    >
                      Найти
                    </button>
                  </div>
                  <div className={`search-settings-special ${theme}`}>
                    <div className="horizontal-col">
                      <div
                        className="project-footer-user "
                        style={{
                          backgroundImage: `url(${currentUser.user_pic})`,
                        }}
                      />

                      <div className="col" style={{ marginLeft: 10 }}>
                        <p className="text small grey">
                          Показывать только задачи со мной
                        </p>
                      </div>
                      <div className="col min">
                        <Switch
                          checked={filterMyTasks}
                          onChange={toggleFilterMyTasks}
                          name="checked"
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </form>

            {/* АКТИВНЫЕ */}
            {showUnsetFilterButton ? (
              <div className="search-filter-wrapper">
                <Link
                  to={window.location.pathname}
                  className="button warning outline small"
                  onClick={unsetFilter}
                >
                  Фильтры <CloseIcon style={{ fontSize: "1.1em" }} />
                </Link>
              </div>
            ) : null}
          </div>

          <ActiveUsers activeSessions={activeSessions} />
          {routeToRedirect == "desk" ? (
            <React.Fragment>
              <Changes projectId={projectId} />
            </React.Fragment>
          ) : null}

          {showUnsetFilterButton ? (
            <Link
              to={window.location.pathname}
              className="top-menu-nav min mobile-only"
              onClick={unsetFilter}
            >
              <CloseIcon className={`icon ${theme}`} />
            </Link>
          ) : null}

          <div className="top-menu-nav min mobile-only">
            <SearchIcon className={`icon ${theme}`} onClick={openSettings} />
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
}
