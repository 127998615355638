// import { useEventCallback } from "@material-ui/core";
import React, { useState, useContext } from "react";
import Linkify from "react-linkify";
import parse from "html-react-parser";

export default function TaskDescriptionReadOnly(props) {
  const { descriptionObj, theme } = props;
  return (
    <div className={`content-wrapper ${theme}`}>
      <div className="row">
        <div className="col min vertical-center">
          <h2 className={`h2 ${theme}`}>Описание</h2>
        </div>
      </div>

      <div className="row permit-text-selection">
        <div className="col pre-line">
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                // style={{ wordBreak: "break-all" }}
                className="link-break-word"
                target="blank"
                href={decoratedHref}
                key={key}
              >
                {decoratedText}
              </a>
            )}
          >
            {descriptionObj !== null ? parse(descriptionObj.content) : ""}
          </Linkify>
        </div>
      </div>
    </div>
  );
}
