import axios from "axios";
import React, { useState, useRef } from "react";
import HOST from "../AppConfig";

//UI
import Slide from "@material-ui/core/Slide";

export default function RestorePassword(props) {
  const { showSnackbar } = props;

  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);

  function openModal() {
    setShowModal(true);
  }
  function closeModal() {
    setShowModal(false);
  }

  function restorePasswordSubmit(event) {
    event.preventDefault();

    axios
      .post(
        `${HOST}/api/restore_password`,
        { email: email },
        { withCredentials: true }
      )
      .then(async (response) => {
        let resp = response.data;
        showSnackbar(resp.message);
        closeModal();
      })
      .catch((error) => {
        if (error.response.status == 400) {
          showSnackbar(error.response.data, "error");
        } else {
          console.log("Restore Password Error: ", error);
        }
      });
  }
  return (
    <React.Fragment>
      <span className="text active" onClick={openModal}>
        Забыли пароль?
      </span>

      {showModal ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeModal}></div>
          <Slide direction="up" in={showModal} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Log in with email</h1>
                </div>
                <div className="modal-content">
                  <input
                    type="text"
                    className="input login-element w-input text-white"
                    maxLength={80}
                    placeholder="Enter your account email"
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                  />
                </div>
                <div className="modal-footer">
                  <button className=" button" onClick={closeModal}>
                    Close
                  </button>
                  <button
                    className=" button primary"
                    onClick={(event) => restorePasswordSubmit(event)}
                  >
                    Send Email
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}
