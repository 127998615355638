import React, { Component, createRef } from "react";
import { format } from "date-fns";
import TaskNameView from "./TaskName/TaskNameView";
import TaskNameReadOnly from "./TaskName/TaskNameReadOnly";
import TaskDescription from "./TaskDescription/TaskDescription";
import TaskDescriptionReadOnly from "./TaskDescription/TaskDescriptionReadOnly";
import LabelView from "./Label/LabelView";
import SettingsView from "./Settings/SettingsView";
import LabelReadOnly from "./Label/LabelReadOnly";
import Subscribers from "./Subscribers/Subscribers";
import SubscribersReadOnly from "./Subscribers/SubscribersReadOnly";
import MessagesView from "./Messages/MessagesView";
import TaskColumn from "./TaskColumn/TaskColumn";
import TaskColumnReadOnly from "./TaskColumn/TaskColumnReadOnly";
import Changes from "./Changes";
import Files from "./Files/Files";
import FilesReadOnly from "./Files/FilesReadOnly";
import TaskDate from "./TaskDate/TaskDate";
import TaskDateReadOnly from "./TaskDate/TaskDateReadOnly";
import ProjectViewContext from "../../Context/ProjectViewContext";
import AppContext from "../../Context/AppContext";
import rest from "../../http/axios";
import TaskViewSkeleton from "./TaskViewSkeleton";
import BudgetLight from "./BudgetLight";
import List from "./List/List";
import ListReadOnly from "./List/ListReadOnly";
import ShareTask from "./ShareTask";
import Diagram from "./Diagram/Diagram";
import LinkedTasksView from "./LinkedTasks/LinkedTasksView";
import WidgetNavView from "./WidgetNavigation/WidgetNavView";
import ActiveSessionsView from "./ActiveSessionsView";
import { parse } from "date-fns";
import { signal } from "@preact/signals-react";

// MATERIAL UI
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { BsPinAngleFill } from "react-icons/bs";
import { FaWindowMinimize } from "react-icons/fa";

export const subscribers = signal([]);

class TaskView extends Component {
  static contextType = ProjectViewContext;

  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      name: "",
      dateFrom: new Date(1990, 1, 1, 9, 0, 0),
      dateOfUpdate: new Date(1990, 1, 1, 9, 0, 0),
      dateTo: new Date(1990, 1, 1, 9, 0, 0),
      columnId: 0,
      columnName: "",
      columnSuccessStatus: false, // Указывает на то, что работы над задачей завершены в этой колонке
      description: {
        id: 0,
        version: 0,
        maxVersion: 0,
        content: "",
        editable: false,
      },
      label: { id: 0, name: "", color: "" },
      lastChange: "",
      subscribers: [],
      messages: [],
      maxMessageId: 0,
      files: [],
      budget: 0,
      listData: { data: [], nextId: 1 },
      pinnedToMainscreen: false,
      pinnedToMainscreen: false,

      widgetSettings: {
        showDescription: true,
        showBudget: false,
        showList: false,
        showFiles: false,
        showLinks: false,
      },
      snackbar: {
        status: false,
        message: "",
        severity: "success", // green
      },

      showAnimationObject: false,
      showSendMessageContainer: true,
      activeSessions: [],
    };

    this.theme = localStorage.getItem("theme");
    this.messageViewRef = createRef();
  }

  componentDidMount() {
    // Загружаю информацию по задаче и подкючение к сокету задачи
    this.loadTaskInfo();
    // Показываю модальное окно
    this.setState({ showAnimationObject: this.props.animate });

    // Если сокет уже подключен, при загразке карточки, то сразу запускаю все слушатели
    if (this.props.socket) {
      this.runSocketListeners(this.props.socket);
    }
    // Подписываюсь на событие нажатия клавиши Esc для закрытия модального окна
    window.addEventListener("keydown", this.handleEscapeKeyDown);

    // Disable browser's default Back button behavior
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = this.handleBackButton;
  }

  componentDidUpdate(prevProps) {
    if (this.props.animate !== prevProps.animate) {
      this.setState({ showAnimationObject: this.props.animate });
    }
    // Если при загрузке карточки сокета не было, а потом он появился, то запускаю все слушатели
    if (this.props.socket !== prevProps.socket) {
      this.runSocketListeners(this.props.socket);
    }
    // Если сокет был отключен, а потом появился, то заново запускаю все слушатели
    if (this.props.showSocketAlert !== prevProps.showSocketAlert) {
      if (this.props.showSocketAlert === false) {
        this.runSocketListeners(this.props.socket);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEscapeKeyDown);
    if (this.props.socket) {
      console.log("Leaving task room");
      // События релевантны только для задачи, поэтому отключа их полностью
      this.props.socket.off("new_message_in_task");
      this.props.socket.off("edit_message_in_task");
      this.props.socket.off("delete_message_in_task");
      this.props.socket.off("update_emojies_in_task");
      this.props.socket.off("update_task_description");
      this.props.socket.off("update_task_list");

      // Эти события используются в DeskView, поэтому отключаю в них функции обработчики
      this.props.socket.off(
        "update_list_of_task_active_users",
        this.updateActiveSessions
      );
      this.props.socket.off("update_task_name", this.updateTaskName);
      this.props.socket.off("update_task_date_to", this.updateTaskDate);
      this.props.socket.off("update_task_label", this.updateTaskLabel);
      this.props.socket.off(
        "add_subscriber_in_task",
        this.updateSubscribersList
      );
      this.props.socket.off("update_user_role_in_task", this.updateUserRole);
      this.props.socket.off("update_task_column", this.updateTaskColumn);
    }
  }

  // This method will be triggered whenever a key is pressed
  handleEscapeKeyDown = (event) => {
    if (event.key === "Escape" || event.keyCode === 27) {
      this.closeTaskModal();
    }
  };

  handleBackButton = () => {
    this.closeTaskModal();
    // // If TaskModel is open, send animation command to TaskModal, when animation finished,
    // // TaskModal calls closeTaskModal() function to unmount div

    // if (taskModalSettings.active) {
    //   let newTaskModalSettings = { ...taskModalSettings };
    //   newTaskModalSettings.animate = false;
    //   setTaskModalSettings(newTaskModalSettings);
    //   navigate("/web_app/project/" + projectId);
    // } else {
    //   // else go back to main page
    //   navigate("/web_app");
    // }

    // // Restore the current state to prevent the browser from going back
    // window.history.pushState(null, null, window.location.href);
  };

  quickSubscribeMeOnTask = () => {
    if (this.state.subscription === false) {
      const user = JSON.parse(localStorage.getItem("current_user"));
      this.subscribeMeOnTask({
        id: user.id,
        user_pic: user.user_pic,
        name: user.name,
      });
    }
  };

  runSocketListeners = (socket) => {
    if (socket) {
      console.log("Start listening task socket events");
      socket.emit("join_task_room", {
        taskId: this.context.taskId,
        projectId: this.context.projectId,
      });
      // Уникальные для TaskView события
      socket.on("new_message_in_task", (data) => {
        this.addMessageToState(data, "socket");
      });
      socket.on("edit_message_in_task", (data) => {
        this.updateMessageInState(data);
      });

      socket.on("delete_message_in_task", (data) => {
        this.deleteMessageFromState(data);
      });
      socket.on("update_emojies_in_task", (data) => {
        this.addEmojiToMessage(data.messageId, data.emojies_data);
      });
      socket.on("update_task_description", (data) => {
        this.updateTaskDescription(data);
      });
      socket.on("update_task_list", (data) => {
        this.updateTaskList(data);
      });

      // Общие события для TaskView и DeskView
      socket.on("update_list_of_task_active_users", (data) => {
        this.updateActiveSessions(data);
      });
      socket.on("update_task_name", (data) => {
        this.updateTaskName(data);
      });
      socket.on("update_task_date_to", (data) => {
        this.updateTaskDate(data);
      });
      socket.on("update_task_label", (data) => {
        this.updateTaskLabel(data);
      });
      socket.on("add_subscriber_to_task", (data) => {
        this.updateSubscribersList(data);
      });
      socket.on("update_user_role_in_task", (data) => {
        this.updateUserRole(data);
      });
      socket.on("update_task_column", (data) => {
        this.updateTaskColumn(data);
      });
    }
  };

  loadTaskInfo = () => {
    if (this.context.taskId != null) {
      rest
        .get("/load_task/" + this.context.taskId)
        .then((response) => {
          if (response.status === 200) {
            let taskObj = response.data;
            let messages = response.data.messages;
            messages.forEach((element) => {
              element.emojies = JSON.parse(element.emojies);
            });

            this.setState({
              id: taskObj.id,
              name: taskObj.name,
              dateFrom: new Date(taskObj.dateFrom),
              dateOfUpdate: new Date(taskObj.dateOfUpdate),
              dateTo: new Date(taskObj.dateTo),
              columnId: taskObj.columnId,
              columnName: taskObj.columnName,
              columnSuccessStatus: taskObj.columnSuccessStatus,
              description: {
                id: taskObj.description.id,
                maxVersion: taskObj.description.version,
                version: taskObj.description.version,
                content: taskObj.description.content,
                editable: taskObj.description.editable,
              },
              label: taskObj.label,
              lastChange: taskObj.lastChange,
              subscription: taskObj.subscription,
              pinnedToMainscreen: taskObj.pinnedToMainscreen,
              subscribers: taskObj.subscribers,
              messages: messages,
              maxMessageId: taskObj.maxMessageId,
              files: taskObj.files,
              budget: taskObj.budget,
              listData:
                taskObj.listData !== undefined
                  ? JSON.parse(taskObj.listData)
                  : { data: [], nextId: 1 },
              widgetSettings: taskObj.widgetSettings,
              activeSessions: taskObj.activeSessions,
            });
            subscribers.value = taskObj.subscribers;
            this.context.cleanNotificationsEvent(taskObj.columnId, taskObj.id);
          }
        })
        .catch((e) => {
          console.log(e);
          setTimeout(() => {
            this.closeTaskModal();
          }, 700);
        });
    }
  };

  updateActiveSessions = (payload) => {
    // Функция имеет влияние на DeskView
    if (payload.taskId !== this.context.taskId) return;
    if (payload.action == "add") {
      this.setState({
        activeSessions: [...this.state.activeSessions, payload.user],
      });
    } else if (payload.action == "remove") {
      this.setState({
        activeSessions: this.state.activeSessions.filter(
          (item) => item.userId !== payload.user.userId
        ),
      });
    }
  };

  updateTaskName = (payload) => {
    // Функция имеет влияние на DeskView
    if (payload.taskId !== this.context.taskId) return;

    // Функция имеет влияние на DeskView
    this.setState({ name: payload.taskName });
    this.quickSubscribeMeOnTask();
  };

  updateTaskDate = (payload) => {
    // Функция имеет влияние на DeskView
    if (payload.taskId !== this.context.taskId) return;

    const dateFormat = "yyyy-MM-dd";
    let dateTo = parse(payload.dateTo, dateFormat, new Date());
    this.setState({ dateTo: dateTo });
    this.quickSubscribeMeOnTask();
  };

  updateTaskLabel = (payload) => {
    if (payload.taskId !== this.context.taskId) return;

    this.setState({
      label: payload.label,
    });
    this.quickSubscribeMeOnTask();
  };

  updateUserRole = (payload) => {
    // Функция имеет влияние на DeskView
    if (payload.taskId !== this.context.taskId) return;

    subscribers.value = subscribers.value.map((user) => {
      if (user.userId === payload.userId) {
        user.role = payload.role;
        user.roleDelivered = payload.roleDelivered;
      }
      return user;
    });
  };

  updateSubscribersList = (payload) => {
    // Функция имеет влияние на DeskView
    if (payload.taskId !== this.context.taskId) return;

    if (payload.action === "add") {
      subscribers.value = [
        {
          userId: payload.userId,
          userPic: payload.userPic,
          userName: payload.userName,
          role: "",
          active: true,
        },
        ...subscribers.value,
      ];
    } else if (payload.action === "remove") {
      subscribers.value = subscribers.value.filter(
        (user) => user.userId !== payload.userId
      );
    }
  };

  subscribeMeOnTask = (currentUser) => {
    let userData = {
      projectId: this.context.projectId,
      taskId: this.context.taskId,
      columnId: this.state.columnId,
      userId: currentUser.id,
      userPic: currentUser.user_pic,
      userName: currentUser.name,
      action: "add",
    };
    this.setState({ subscription: true });
    this.props.socket.emit("add_subscriber", userData);
    this.updateSubscribersList(userData);
    this.context.adoptTaskSubscribersInProject(userData);
  };

  unsubscribeMeOnTask = (currentUser) => {
    let userData = {
      projectId: this.context.projectId,
      taskId: this.context.taskId,
      columnId: this.state.columnId,
      userId: currentUser.id,
      action: "remove",
    };

    this.props.socket.emit("remove_subscriber", userData, () => {
      subscribers.value = subscribers.value.filter((item) =>
        item.userId === currentUser.id ? false : true
      );

      this.context.adoptTaskSubscribersInProject(userData);
    });
    this.setState({ subscription: false });
  };

  updateTaskColumn = (payload) => {
    // Функция имеет влияние на DeskView
    if (payload.taskId !== this.context.taskId) return;

    this.setState({
      columnId: payload.targetColumnId,
      columnName: payload.targetColumnName,
      columnSuccessStatus: false,
    });
    this.quickSubscribeMeOnTask();
  };

  updateTaskColumnSuccessStatus = (status) => {
    // Функция пока не синхронизируется по soket
    this.setState({
      columnSuccessStatus: status, // true/false
    });
    this.quickSubscribeMeOnTask();
  };

  saveBudgetHandler = (budget) => {
    // Функция пока не синхронизируется по soket
    rest
      .post("/update_budget", {
        projectId: this.context.projectId,
        taskId: this.context.taskId,
        budget: budget,
      })
      .then((response) => {
        // let resp = response.data;
        this.setState({ budget: budget });
        this.context.adoptBudgetlInTask(
          this.state.columnId,
          this.state.id,
          budget
        );
      });
    // .catch((e) => {});
  };

  updateTaskDescription = (payload, updateMaxVersion = true) => {
    // Функция используется локально в TaskView, пересеченией в DeskView нет
    this.setState({
      description: {
        id: payload.id,
        maxVersion: payload.maxVersion,
        version: payload.version,
        content: payload.content,
        editable: payload.editable,
      },
    });
    this.quickSubscribeMeOnTask();
  };

  updateTaskList = (payload) => {
    // Функция используется локально в TaskView, пересеченией в DeskView нет

    // формат payload.listData = {data: [], nextId: 1}
    if (typeof payload.listData == "string") {
      // при получении данных из сокета они приходят в формте строки
      try {
        let listData = JSON.parse(payload.listData);
        this.setState({
          listData: listData,
        });
      } catch (e) {
        console.log(e);
        return;
      }
    }
  };

  addMessageToState = (
    d = {
      taskId: 0,
      userId: 0,
      userName: "",
      userPic: "",
      messageId: 0,
      message: "",
      answerId: 0,
      delivered: false,
      answerFlag: false,
      answerId: 0,
      answerText: "",
      answerName: "",
      dateFrom: "20 10 2023 10 00",
    },
    trigger = "me"
  ) => {
    // Функция используется локально в TaskView, пересеченией в DeskView нет

    // не допускаю добавление задачи, если в событии указан другой taskId
    // это может случиться, если сокет не успел отключиться при переходе на другую задачу
    if (d.taskId !== this.context.taskId) return;
    let msgData = {
      id: d.messageId,
      userId: d.userId,
      userName: d.userName,
      userPic: d.userPic,
      message: d.message,
      dateFrom: format(new Date(), "dd.MM.yyyy"),
      delivered: d.delivered,
      emojies: [],
      dateFrom: d.dateFrom, // "20 10 2023 10 00"
    };

    if (d.answerFlag === true) {
      msgData.answerId = d.answerId;
      msgData.answerMessage = d.answerText;
      msgData.answerUserName = d.answerName;
    }

    this.setState({
      messages: [msgData, ...this.state.messages],
      maxMessageId: d.messageId,
    });

    if (trigger === "me") {
      this.quickSubscribeMeOnTask();
    }
  };

  updateMessageInState = (messageId, data, type = "updateId") => {
    // Функция используется локально в TaskView, пересеченией в DeskView нет
    this.setState({
      messages: this.state.messages.map((item) => {
        if (item.id === messageId) {
          if (type == "updateId") {
            item.delivered = true;
            item.id = data;
          } else if (type == "updateText") {
            item.message = data;
          }
        }
        return item;
      }),
    });
  };

  deleteMessageFromState = (payload) => {
    // Функция используется локально в TaskView, пересеченией в DeskView нет
    this.setState({
      messages: this.state.messages.filter(
        (item) => item.id !== payload.messageId
      ),
    });
  };

  addEmojiToMessage = (messageId, data) => {
    // Функция используется локально в TaskView, пересеченией в DeskView нет
    this.setState({
      messages: this.state.messages.map((item) => {
        if (item.id === messageId) {
          item.emojies = data;
        }
        return item;
      }),
    });
  };

  addFileHandler = (file) => {
    // Функция используется локально в TaskView, пересеченией в DeskView нет
    const fd = new FormData();
    fd.append("uploadFile", file, file.name);
    fd.append("projectId", this.context.projectId);
    fd.append("taskId", this.context.taskId);

    rest
      .post("/add_file", fd, {
        onUploadProgress: (progressEvent) => {
          console.log("PROGRESS: ", progressEvent.loaded / progressEvent.total);
        },
      })
      .then((response) => {
        const rsp = response.data;
        this.setState({
          files: [
            {
              id: rsp.fileId,
              name: rsp.fileName,
              dateFrom: 0,
              filePath: rsp.filePath,
              thumbPath: rsp.thumbPath,
            },
            ...this.state.files,
          ],
        });
        this.showSnackBar("Файл добавлен");
        this.quickSubscribeMeOnTask();
      })
      .catch((e) => {
        this.showSnackBar("Ошибка добавления файла", "error");
        console.log(e);
      });
  };

  deleteFileHandler = (fileId) => {
    // Функция используется локально в TaskView, пересеченией в DeskView нет
    rest
      .post("/remove_file", {
        fileId: fileId,
        taskId: parseInt(this.context.taskId),
      })
      .then((response) => {
        this.setState({
          files: this.state.files.filter((item) => item.id !== fileId),
        });
        this.quickSubscribeMeOnTask();
      })
      .catch((e) => {
        console.log(e.response.status, e.response.data);
      });
  };

  closeTaskModal = (event) => {
    // Удаляю задачу из нижней панели мини-задач
    this.props.removeMinifiedTaskEvent(this.state.id);

    // Выхожу из комнаты
    if (this.props.socket) {
      this.props.socket.emit("leave_task_room", {
        taskId: this.context.taskId,
      });
    }

    // Вызываю метод анимации закрытия модального окна
    // вызов функции, которая unmoount-ит окно, запускается в <Slide> после окончания анимации
    this.setState({ showAnimationObject: false });
    window.history.replaceState(
      null,
      null,
      "/web_app/project/" + this.context.projectId
    );
  };

  minifyTaskModal = () => {
    const taskObj = {
      taskId: this.state.id,
      taskName: this.state.name,
      label: this.state.label.name,
      labelColor: this.state.label.color,
    };
    this.props.addMinifiedTaskEvent(taskObj);

    // Выхожу из комнаты
    this.props.socket.emit("leave_task_room", {
      taskId: this.context.taskId,
    });

    // Вызываю метод анимации закрытия модального окна
    // вызов функции, которая unmoount-ит окно, запускается в <Slide> после окончания анимации
    this.setState({ showAnimationObject: false });

    window.history.replaceState(
      null,
      null,
      "/web_app/project/" + this.context.projectId
    );
  };

  showSnackBar = (message, severity = "success") => {
    this.setState({
      snackbar: { status: true, message: message, severity: severity },
    });
  };

  hideSnackBar = () => {
    this.setState({ snackbar: { status: false, message: "" } });
  };

  pinToMainscreen = (flag) => {
    rest
      .post("/pin_task_to_mainscreen", {
        projectId: this.context.projectId,
        taskId: this.state.id,
        pinFlag: flag,
      })
      .then((response) => {
        // const rsp = response.data;
        this.setState({ pinnedToMainscreen: flag });
        this.context.adoptTaskPinInProject({
          columnId: this.state.columnId,
          taskId: this.state.id,
          pinFlag: flag,
        });
      });
  };

  changeWidgetSettings = (target_widget, status) => {
    let widgetSettings = this.state.widgetSettings;
    widgetSettings[target_widget] = status;

    rest
      .post("/update_widget_settings", {
        projectId: this.context.projectId,
        taskId: this.context.taskId,
        widgetName: target_widget,
        widgetStatus: status,
      })
      .then(() => {
        this.setState({ widgetSetting: widgetSettings });
      });
  };

  setShowSendMessageContainer = (value) => {
    // Функция управляет отображение формы отправки комментария в мобильной версии
    this.setState({ showSendMessageContainer: value });
  };

  render() {
    return (
      <AppContext.Consumer>
        {(appContext) => (
          <React.Fragment>
            <div
              className={`task-cover-zone active`}
              onClick={() => this.closeTaskModal()}
            ></div>
            <div className={`task-modal-wrapper ${this.theme}`}>
              <Slide
                direction="up"
                in={this.state.showAnimationObject}
                onExited={() => {
                  this.props.closeTaskModalEvent();
                }}
                mountOnEnter
                unmountOnExit
              >
                <div className={`task-modal ${this.theme}`}>
                  <div className={`modal-topline ${this.theme}`}>
                    <div
                      className="top-menu-nav min mobile-only"
                      onClick={() => this.closeTaskModal()}
                    >
                      <div className={`active-icon ${this.theme}`}>
                        <CloseIcon />
                      </div>
                    </div>
                    <div
                      className="top-menu-nav min mobile-only"
                      onClick={() => this.minifyTaskModal()}
                    >
                      <div className={`active-icon ${this.theme}`}>
                        <FaWindowMinimize />
                      </div>
                    </div>
                    {this.context.userRole === "admin" ? (
                      <LabelView
                        columnId={this.state.columnId}
                        activeLabel={this.state.label}
                        updateTaskLabel={this.updateTaskLabel}
                        theme={this.theme}
                      />
                    ) : (
                      <LabelReadOnly
                        activeLabel={this.state.label}
                        theme={this.theme}
                      />
                    )}

                    <SettingsView
                      theme={this.theme}
                      columnId={this.state.columnId}
                      closeTaskModalEvent={this.closeTaskModal}
                    />
                    <div
                      className="top-menu-nav min desktop-only"
                      onClick={() => this.minifyTaskModal()}
                    >
                      <div className={`active-icon ${this.theme}`}>
                        <FaWindowMinimize />
                      </div>
                    </div>
                    <div
                      className="top-menu-nav min desktop-only"
                      onClick={() => this.closeTaskModal()}
                    >
                      <div className={`active-icon ${this.theme}`}>
                        <CloseIcon />
                      </div>
                    </div>
                  </div>

                  {this.state.id !== 0 ? (
                    <div className="modal-activity">
                      <div className={`modal-header ${this.theme}`}>
                        {/* <TaskCategory /> */}
                        {this.context.userRole === "admin" ? (
                          <TaskNameView
                            name={this.state.name}
                            columnId={this.state.columnId}
                            updateTaskName={this.updateTaskName}
                            setShowSendMessageContainerEvent={
                              this.setShowSendMessageContainer
                            }
                            theme={this.theme}
                          />
                        ) : (
                          <TaskNameReadOnly
                            name={this.state.name}
                            theme={this.theme}
                          />
                        )}

                        <div className="row">
                          {/* TASK DATE */}
                          {this.context.userRole === "admin" ? (
                            <TaskDate
                              dateTo={this.state.dateTo}
                              columnId={this.state.columnId}
                              updateTaskDate={this.updateTaskDate}
                              theme={this.theme}
                            />
                          ) : (
                            <TaskDateReadOnly
                              dateTo={this.state.dateTo}
                              theme={this.theme}
                            />
                          )}
                          {/* TASK COLUMN */}
                          {this.context.userRole === "admin" ? (
                            <TaskColumn
                              columnId={this.state.columnId}
                              columnName={this.state.columnName}
                              columnSuccessStatus={
                                this.state.columnSuccessStatus
                              }
                              updateTaskColumn={this.updateTaskColumn}
                              updateTaskColumnSuccessStatus={
                                this.updateTaskColumnSuccessStatus
                              }
                              theme={this.theme}
                            />
                          ) : (
                            <TaskColumnReadOnly
                              currentColumn={this.state.columnName}
                              theme={this.theme}
                            />
                          )}
                        </div>

                        {/* TASK SUBSCRIBERS */}
                        {this.context.userRole === "admin" ? (
                          <Subscribers
                            columnId={this.state.columnId}
                            updateSubscribersList={this.updateSubscribersList}
                            updateUserRole={this.updateUserRole}
                            socket={this.props.socket}
                            showSnackBar={this.showSnackBar}
                            // projectId={this.context.projectId}
                          />
                        ) : (
                          <SubscribersReadOnly />
                        )}

                        {/* NAVIGATION */}
                        <div className="modal-navigation">
                          {this.state.subscription ? (
                            <div className="col min">
                              <button
                                className={`button ${this.theme}`}
                                onClick={() => {
                                  if (subscribers.value.length === 1) {
                                    this.showSnackBar(
                                      "В задаче должен остаться хотя бы один участник",
                                      "error"
                                    );
                                    return;
                                  }
                                  this.unsubscribeMeOnTask(
                                    appContext.currentUser
                                  );

                                  // Ручным образом меняю состояние прикрепления, потому что запись на сервере
                                  // которая это регулировала удалит строчкой выше
                                  this.setState({ pinnedToMainscreen: false });
                                  this.context.adoptTaskPinInProject({
                                    columnId: this.state.columnId,
                                    taskId: this.state.id,
                                    pinFlag: false,
                                  });
                                }}
                              >
                                Отписаться
                              </button>
                            </div>
                          ) : (
                            <div className="col min">
                              <button
                                className={`button primary ${this.theme}`}
                                onClick={() =>
                                  this.subscribeMeOnTask(appContext.currentUser)
                                }
                              >
                                Подписаться
                              </button>
                            </div>
                          )}

                          {this.state.subscription ? (
                            <div>
                              {this.state.pinnedToMainscreen ? (
                                <div className="col min">
                                  <button
                                    className={`button inactive ${this.theme}`}
                                    onClick={() => this.pinToMainscreen(false)}
                                  >
                                    <FiberManualRecordIcon
                                      style={{
                                        fontSize: "1em",
                                        marginRight: "5px",
                                        color: "grey",
                                      }}
                                    />
                                    Открепить
                                  </button>
                                </div>
                              ) : (
                                <div className="col min">
                                  <button
                                    className={`button ${this.theme}`}
                                    onClick={() => this.pinToMainscreen(true)}
                                  >
                                    <BsPinAngleFill
                                      className="icon small"
                                      style={{
                                        marginRight: "5px",
                                        color: "#f36c3e",
                                      }}
                                    />
                                    Закрепить
                                  </button>
                                </div>
                              )}
                            </div>
                          ) : null}
                          <div className="col min">
                            <ShareTask
                              changeWidgetSettingsEvent={
                                this.changeWidgetSettings
                              }
                              taskId={this.context.taskId}
                              taskName={this.state.name}
                              showSnackBar={this.showSnackBar}
                              theme={this.theme}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="modal-body">
                        <div className="col col-6 full-screen-on-mobile">
                          {/* DESCRIPTION */}
                          {this.state.widgetSettings.showDescription ? (
                            <React.Fragment>
                              {this.context.userRole === "admin" ? (
                                <TaskDescription
                                  descriptionObj={this.state.description}
                                  theme={this.theme}
                                  updateVersionInDescription={
                                    this.updateVersionInDescription
                                  }
                                  setShowSendMessageContainerEvent={
                                    this.setShowSendMessageContainer
                                  }
                                  updateTaskDescription={
                                    this.updateTaskDescription
                                  }
                                  changeWidgetSettingsEvent={
                                    this.changeWidgetSettings
                                  }
                                />
                              ) : (
                                <TaskDescriptionReadOnly
                                  descriptionObj={this.state.description}
                                  theme={this.theme}
                                />
                              )}
                            </React.Fragment>
                          ) : null}

                          {/* FILES */}
                          {this.state.widgetSettings.showFiles ? (
                            <React.Fragment>
                              {this.context.userRole === "admin" ? (
                                <Files
                                  projectId={this.context.projectId}
                                  taskId={this.context.taskId}
                                  files={this.state.files}
                                  theme={this.theme}
                                  addFile={this.addFileHandler}
                                  deleteFile={this.deleteFileHandler}
                                  changeWidgetSettingsEvent={
                                    this.changeWidgetSettings
                                  }
                                  showSnackBar={this.showSnackBar}
                                />
                              ) : (
                                <FilesReadOnly
                                  files={this.state.files}
                                  theme={this.theme}
                                  showSnackBar={this.showSnackBar}
                                />
                              )}
                            </React.Fragment>
                          ) : null}

                          {/* LIST */}
                          {this.state.widgetSettings.showList ? (
                            <React.Fragment>
                              {this.context.userRole === "admin" ? (
                                <List
                                  listData={this.state.listData}
                                  changeWidgetSettingsEvent={
                                    this.changeWidgetSettings
                                  }
                                  theme={this.theme}
                                />
                              ) : (
                                <ListReadOnly
                                  listData={this.state.listData}
                                  theme={this.theme}
                                />
                              )}
                            </React.Fragment>
                          ) : null}
                        </div>
                        <div className="col">
                          <Changes
                            dateFrom={this.state.dateFrom}
                            dateOfUpdate={this.state.dateOfUpdate}
                            taskId={this.context.taskId}
                            lastChange={this.state.lastChange}
                            theme={this.theme}
                          />
                          <Diagram theme={this.theme} />

                          {this.state.widgetSettings.showBudget &&
                          this.context.userRole === "admin" ? (
                            <BudgetLight
                              budget={this.state.budget}
                              saveBudget={this.saveBudgetHandler}
                              changeWidgetSettingsEvent={
                                this.changeWidgetSettings
                              }
                              theme={this.theme}
                            />
                          ) : null}
                          <ActiveSessionsView
                            activeSessions={this.state.activeSessions}
                          />
                        </div>
                      </div>
                      {this.state.widgetSettings.showLinks ? (
                        <LinkedTasksView
                          projectId={this.context.projectId}
                          taskId={this.context.taskId}
                          showTaskModalEvent={this.props.showTaskModalEvent}
                          changeWidgetSettingsEvent={this.changeWidgetSettings}
                          showSnackBarEvent={this.showSnackBar}
                          theme={this.theme}
                        />
                      ) : null}

                      {this.context.userRole === "admin" ? (
                        <WidgetNavView
                          widgetSettings={this.state.widgetSettings}
                          changeWidgetSettingsEvent={this.changeWidgetSettings}
                        />
                      ) : null}
                      <div ref={this.messageViewRef} />
                      <MessagesView
                        socket={this.props.socket}
                        taskId={this.context.taskId}
                        messages={this.state.messages}
                        maxMessageId={this.state.maxMessageId}
                        theme={this.theme}
                        addMessageToState={this.addMessageToState}
                        updateMessageInState={this.updateMessageInState}
                        deleteMessageFromState={this.deleteMessageFromState}
                        showSendMessageContainer={
                          this.state.showSendMessageContainer
                        }
                        addEmojiInState={this.addEmojiToMessage}
                      />
                    </div>
                  ) : (
                    <TaskViewSkeleton theme={this.theme} />
                  )}
                </div>
              </Slide>
            </div>
            {/* <div
              className="scroll-to-messages"
              onClick={() => {
                this.messageViewRef.current.scrollIntoView();
              }}
            >
              <BsChevronDown style={{ width: "100%", height: "100%" }} />
            </div> */}
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={this.state.snackbar.status}
              autoHideDuration={4000}
              onClose={this.hideSnackBar}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                severity={this.state.snackbar.severity}
                onClose={this.hideSnackBar}
              >
                {this.state.snackbar.message}
              </MuiAlert>
            </Snackbar>
          </React.Fragment>
        )}
      </AppContext.Consumer>
    );
  }
}

export default TaskView;
