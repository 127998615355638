import React, { useState, useContext, useEffect, useCallback } from "react";
// import rest from "../../../http/axios";
import ListElement from "./ListElement";
import ListDeliminator from "./ListDeliminator";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import BasicContext from "../../../Context/BasicContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// ICONS
import CloseIcon from "@material-ui/icons/Close";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function List(props) {
  const { taskId, projectId } = useContext(ProjectViewContext);
  const { socket } = useContext(BasicContext);

  const { changeWidgetSettingsEvent, theme } = props;

  // Пример значение в list:
  // [{ id: 0, desc: "hello", check: false, editable: true }]
  const [list, setList] = useState([]);
  const [nextId, setNextId] = useState(0);

  const [showWidgetNav, setShowWidgetNav] = useState(false);

  useEffect(() => {
    if (props.listData.data.length > 0) {
      setList(props.listData.data);
      setNextId(props.listData.nextId);
    }
  }, [props.listData]);

  const onDragEnd = useCallback((result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    const items = reorder(list, result.source.index, result.destination.index);
    syncWithServer(items);
    setList(items);
  });

  function toggleCheck(id, check) {
    let newList = [...list];
    newList.map((item) => {
      if (item.id === id) {
        item.check = check;
      }
      return item;
    });
    syncWithServer(newList);
    setList(newList);
  }

  function updateDescHandler(id, desc) {
    let newList = [...list];
    newList.map((item) => {
      if (item.id === id) {
        item.desc = desc;
        item.editable = false;
      }
      return item;
    });
    syncWithServer(newList);
    setList(newList);
  }

  function deleteElementHandler(id) {
    let newList = [...list];
    newList = newList.filter((item) => item.id !== id);
    syncWithServer(newList);
    setList(newList);
  }

  const addElement = (delimitator = false) => {
    let element;
    if (delimitator === true) {
      element = { id: nextId, desc: "Новый подраздел", type: "delimitator" };
    } else {
      element = {
        id: nextId,
        desc: "",
        check: false,
        editable: true, // прокидывает статус редактирования в момент создания элемента
        type: "checkout",
      };
    }
    if (element == undefined) return;

    setTimeout(function() {
      let newList = [...list, element];
      syncWithServer(newList);
      setList(newList);
      setNextId(nextId + 1);
    }, 200);
  };

  function syncWithServer(data) {
    let listData = { data: data, nextId: nextId };
    socket.emit("update_task_list", {
      listData: JSON.stringify(listData),
      taskId: taskId,
      projectId: projectId,
    });
  }

  return (
    <div className={`content-wrapper ${theme}`}>
      <div className="row">
        {showWidgetNav ? (
          <div className="col min vertical-center">
            <CloseIcon
              className="icon small"
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => {
                setShowWidgetNav(!showWidgetNav);
              }}
              // onClick={() => {
              //   changeWidgetSettingsEvent("showList", false);
              // }}
            />
          </div>
        ) : null}
        <div className="col min vertical-center">
          <h2
            className="h2 active"
            onClick={() => {
              setShowWidgetNav(!showWidgetNav);
            }}
          >
            Список задач
          </h2>
        </div>
        {showWidgetNav ? (
          <div className="col min vertical-center">
            <button
              className="button danger outline small"
              onClick={() => {
                changeWidgetSettingsEvent("showList", false);
              }}
            >
              Скрыть раздел
            </button>
          </div>
        ) : (
          <>
            <div className="col min vertical-center">
              <button className={`button small`} onClick={() => addElement()}>
                + Пункт
              </button>
            </div>
            <div className="col min vertical-center">
              <button
                className={`button small`}
                onClick={() => addElement(true)}
              >
                + Подраздел
              </button>
            </div>
          </>
        )}
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="listDroppableZone">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {list.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className="row"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {item.type == "delimitator" ? (
                        <ListDeliminator
                          item={item}
                          key={item.id}
                          theme={theme}
                          deleteElement={deleteElementHandler}
                          updateDesc={updateDescHandler}
                        />
                      ) : (
                        <ListElement
                          item={item}
                          key={item.id}
                          theme={theme}
                          toggleCheckEvent={toggleCheck}
                          updateDesc={updateDescHandler}
                          deleteElement={deleteElementHandler}
                          addElement={addElement}
                        />
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
