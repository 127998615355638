import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rest from "../http/axios";
import Linkify from "react-linkify";
import Switch from "@material-ui/core/Switch";

export default function MessagesView() {
  const [currentPage, setCurrentPage] = useState(1);
  const [messages, setMessages] = useState([]);
  const [messagesFilterStatus, setMessagesFilterStatus] = useState(
    localStorage.getItem("messagesFilter") == "new" ? true : false
  );
  const [messagesFilterName, setMessagesFilterName] = useState(
    localStorage.getItem("messagesFilter")
      ? localStorage.getItem("messagesFilter")
      : "all"
  );
  const [contetLoaded, setContetLoaded] = useState(false);
  const [persmissionForLoadNextPage, setPersmissionForLoadNextPage] = useState(
    true
  );
  const [enableLoadOnScroll, setEnableLoadOnScroll] = useState(true);

  const theme = localStorage.getItem("theme");
  const navigate = useNavigate();
  const contentContainerRef = useRef(null);

  useEffect(() => {
    loadNextPage(1);
  }, []);

  const handleScroll = (event) => {
    const {
      scrollTop,
      clientHeight,
      scrollHeight,
    } = contentContainerRef.current;

    // console.log(scrollTop + clientHeight + 150, scrollHeight);
    if (scrollTop + clientHeight + 200 > scrollHeight) {
      if (enableLoadOnScroll == false) {
        return;
      }
      setEnableLoadOnScroll(false);
    }
  };
  useEffect(() => {
    if (enableLoadOnScroll == false) {
      loadNextPage(currentPage);
    }
  }, [enableLoadOnScroll]);

  const loadNextPage = (currentPage) => {
    if (persmissionForLoadNextPage == true) {
      rest
        .get(`/load_chats/${currentPage}/${messagesFilterName}`)
        .then((response) => {
          let rsp = response.data;
          let allMessages = messages.concat(rsp.chats);

          setMessages(allMessages);
          setCurrentPage(currentPage + 1);
          setContetLoaded(true);
          setEnableLoadOnScroll(true);
          setPersmissionForLoadNextPage(rsp.chats.length < 20 ? false : true);
        })
        .catch((error) => {
          console.log("Ответ сервера", error.response.data);
        });
    } else {
      console.log("No permission for loading more");
    }
  };

  const switchAcivityFilter = () => {
    let newFilterName = "";
    if (messagesFilterStatus == false) {
      newFilterName = "new";
    } else {
      newFilterName = "all";
    }

    setCurrentPage(1);
    setMessagesFilterStatus(!messagesFilterStatus);
    setMessagesFilterName(newFilterName);
    setMessages([]);
    setContetLoaded(false);
    setPersmissionForLoadNextPage(true);

    localStorage.setItem("messagesFilter", newFilterName);
  };
  useEffect(() => {
    loadNextPage(1);
  }, [messagesFilterName]);

  return (
    <div
      className={`content-container ${theme}`}
      ref={contentContainerRef}
      onScroll={(event) => handleScroll(event)}
    >
      <div className={`content-wrapper ${theme}`}>
        <div className="row">
          <div className="col vertical-center">
            <h1 className="h1">Сообщения</h1>
          </div>

          <div className="col min vertical-center">
            <span className="text" onClick={switchAcivityFilter}>
              Все
            </span>
          </div>
          <div className="col min vertical-center">
            <Switch
              checked={messagesFilterStatus}
              onChange={switchAcivityFilter}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>
          <div
            className="col min vertical-center"
            style={{ marginRight: "10px" }}
          >
            <span className="text" onClick={switchAcivityFilter}>
              Новые
            </span>
          </div>
          {/* <div className="col min vertical-center">
            <div className="button-wrapper">
              <button className="button sky" onClick={this.markAsRead}>
                <DraftsIcon
                  style={{
                    marginRight: "5px",
                    fontSize: "1.3em",
                  }}
                />
                Прочитать все
              </button>
            </div>
          </div> */}
        </div>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              className="link-break-word"
              target="blank"
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </a>
          )}
        >
          {messages.map((item) => (
            <div
              key={item.messageId}
              to={`/web_app/project/${item.projectId}/task/${item.taskId}`}
              className={`ntfc-row ${item.active} ${theme}`}
            >
              <div
                className="ntfc-body"
                onClick={() => {
                  navigate(
                    `/web_app/project/${item.projectId}/task/${item.taskId}`
                  );
                }}
              >
                <div className="col min">
                  <div
                    className="message-img"
                    style={{ backgroundImage: `url(${item.userPic})` }}
                  />
                </div>
                <div className="col">
                  <div className="ntfc-body-limiter">
                    <span className="text pre-line">
                      <b>{item.taskName}</b>
                    </span>
                  </div>
                  <div className="ntfc-body-limiter">
                    <span className={`text pre-line ${theme}`}>
                      {item.author}: {item.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Linkify>

        {contetLoaded ? (
          <React.Fragment>
            {messages.length === 0 ? (
              <div className="row">
                <span className="text">У вас пока нет сообщений</span>
              </div>
            ) : null}
          </React.Fragment>
        ) : (
          <div className="row">
            <span className="text">Идет загрузка...</span>
          </div>
        )}
      </div>
    </div>
  );
}

// markAsRead = () => {
//   rest.post("/mark_as_read/messages");
//   let newMsgDataset = this.state.messages.map((item) => {
//     item.active = "";
//     return item;
//   });

//   this.setState({ messages: newMsgDataset });
// };
