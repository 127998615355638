import React, { useState, useEffect, useRef, useContext } from "react";
import TextareaAutosize from "react-textarea-autosize";

// UI
import SendIcon from "@material-ui/icons/Send";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

export default function SendMessage(props) {
  const {
    messageHelperProps,
    userPic,
    typingProps,
    theme,
    showSendMessageContainer,
    sendMessageEvent,
    sendTypingEventOnServer,
  } = props;

  const [input, setInput] = useState("");
  // Указывает на то, нажата ли клавиша ctrl (используется для hotkey crl+enter)
  const [ctrlHotkey, setCtrlHotkey] = useState(false);

  const [typingTimestamp, setTypingTimestamp] = useState(new Date().getTime());

  const textareaRef = useRef();

  useEffect(() => {
    if (messageHelperProps.quoteId > 0) {
      textareaRef.current.focus();
    }
    // если приходят новые props, обновляем текст в input
    if (messageHelperProps.action == "edit") {
      setInput(messageHelperProps.quoteText);
    }
  }, [messageHelperProps]);

  // регулярно отправляем в MessageView.js данные о том, что пользователь печатает
  const onChange = (event) => {
    setInput(event.target.value);
    if (new Date().getTime() - typingTimestamp > 3000) {
      sendTypingEventOnServer();
      setTypingTimestamp(new Date().getTime());
    }
  };

  function sendMessage() {
    if (input.trim().length > 0) {
      sendMessageEvent(input);
      setInput("");
    }
  }

  function serveKeyDown(event) {
    if (event.key == "Control" || event.key == "Meta") {
      setCtrlHotkey(true);
    }
    if (event.key == "Enter" && ctrlHotkey == true) {
      sendMessage();
    }
  }

  function serveKeyUp(event) {
    if (event.key == "Control" || event.key == "Meta") {
      setCtrlHotkey(false);
    }
  }

  return (
    // showSendMessageContainer принимает значения true/false
    // используется для скрытия окна отправки сообщения при редактировании описания
    // применяется в <TaskNameView /> и <TaskDescriptionView />
    <div
      className={`send-message-container ${
        showSendMessageContainer ? "" : "hide"
      } ${theme}`}
    >
      {messageHelperProps.action !== "" ? (
        <div className="quote">
          <div className="col min" style={{ marginRight: "10px" }}>
            {messageHelperProps.action == "answer" ? (
              <QuestionAnswerIcon />
            ) : null}
            {messageHelperProps.action == "edit" ? <EditIcon /> : null}
          </div>
          <div className="col">
            {messageHelperProps.action == "answer" ? (
              <span>Ответ на:</span>
            ) : null}
            {messageHelperProps.action == "edit" ? (
              <span>Редактирование:</span>
            ) : null}

            <span>
              {messageHelperProps.quoteText.length > 60
                ? messageHelperProps.quoteText.substring(0, 60) + "..."
                : messageHelperProps.quoteText}
            </span>
          </div>
          <div className="col min" style={{ marginLeft: "10px" }}>
            <CloseIcon
              className="active-icon grey"
              onClick={() => {
                props.closeMessageHelper();
                setInput("");
              }}
            />
          </div>
        </div>
      ) : null}

      <div className="send-message-wrapper">
        <div className="col min desktop-only">
          <div
            className="message-img"
            style={{ backgroundImage: `url(${userPic})` }}
          />
        </div>
        <div className="col message-input-wrapper">
          <TextareaAutosize
            placeholder="Написать комментарий"
            className={`textarea w-input`}
            onChange={onChange}
            value={input}
            maxRows={5}
            ref={textareaRef}
            onKeyDown={(event) => serveKeyDown(event)}
            onKeyUp={(event) => serveKeyUp(event)}
            // onFocus={handleOnFocusTextarea}
            // onBlur={handleOnBlurTextarea}
            tabIndex={0}
          />
          {typingProps.show == true ? (
            <div className="typing-container">
              <i className="text small blue">{typingProps.name} печатает...</i>
            </div>
          ) : null}
        </div>
        <div className="col min">
          <button className={`button ${theme}`} onClick={() => sendMessage()}>
            <SendIcon />
            <span className="desktop-only">&#8194;Отправить</span>
          </button>
        </div>
      </div>
    </div>
  );
}
