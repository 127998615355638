import React from "react";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { BsCalendarEvent, BsFillFastForwardFill, BsBell } from "react-icons/bs";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone"; // Make sure to import this correctly

const ntfcImages = {
  emoji: <MdOutlineEmojiEmotions />,
  change_date: <BsCalendarEvent />,
  move: <BsFillFastForwardFill />,
  "": <BsBell />,
};

function NotificationComponent({ notificationType, count }) {
  return (
    <li className="footer-info-item active success">
      {ntfcImages[notificationType]}&#160; {count}
    </li>
  );
}

export default NotificationComponent;
