import React, { useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// ICONS
import ChatIcon from "@material-ui/icons/Chat";
import { BsChevronRight, BsChevronLeft, BsFillEyeFill } from "react-icons/bs";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

export default function ProjectListCard(props) {
  const { item, theme, acceptInviteEvent, declineInviteEvent } = props;
  const navigate = useNavigate();
  const [showNavigation, setShowNavigation] = useState(false);

  const taskLinksRef = useRef(null);

  const scrollContainer = (direction) => {
    if (direction === "left") {
      taskLinksRef.current.scrollLeft -= 260;
    }
    if (direction === "right") {
      taskLinksRef.current.scrollLeft += 260;
    }
  };

  return (
    <div
      className={`project-card-wrap ${theme}`}
      onMouseEnter={() => {
        try {
          if (
            taskLinksRef.current.scrollWidth > taskLinksRef.current.clientWidth
          ) {
            setShowNavigation(true);
          }
        } catch (e) {
          setShowNavigation(false);
        }
      }}
      onMouseLeave={() => setShowNavigation(false)}
    >
      <Link
        to={`/web_app/project/${item.id}`}
        className="project-card-head"
        onClick={() => {
          // создаю пустой аудио объект, чтобы браузер разрешил воспроизведение звука
          const notificationSound = new Audio(
            "https://branzy.club/static_files/system_audio/chat_notification.mp3"
          );
        }}
      >
        <div className={`project-card-info ${theme}`}>
          <h1>{item.name}</h1>
          {item.inviteAccepted === false ? (
            <React.Fragment>
              <span className={`text bold ${theme}`}>
                Вы приглашены в проект:
              </span>
              <div className="row">
                <div className="col">
                  <button
                    className="button "
                    onClick={(event) => {
                      event.preventDefault();
                      declineInviteEvent(item.id);
                    }}
                  >
                    Отказаться
                  </button>
                </div>
                <div className="col">
                  <button
                    className="button done"
                    onClick={(event) => {
                      event.preventDefault();
                      acceptInviteEvent(item.id);
                    }}
                  >
                    Согласиться
                  </button>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="project-card-item">
                <span className={`text small ${theme} icon-centered`}>
                  <BsFillEyeFill
                    className="icon small"
                    style={{ marginRight: "3px" }}
                  />
                  Просмотров: {item.visits}
                </span>
              </div>

              <div className="toast-wrapper">
                <Link to={`/web_app/project/${item.id}?active=true`}>
                  {item.msgNtfc > 0 ? (
                    <div className={`toast primary ${theme}`}>
                      <div>
                        <ChatIcon style={{ fontSize: 15 }} />
                        &#160; Сообщений:&nbsp;{item.msgNtfc}
                      </div>
                    </div>
                  ) : null}

                  {item.changesNtfc > 0 ? (
                    <div className={`toast success ${theme}`}>
                      <NotificationsNoneIcon style={{ fontSize: 15 }} />
                      &#160;Изменений:&nbsp;{item.changesNtfc}
                    </div>
                  ) : null}
                </Link>
              </div>
            </React.Fragment>
          )}
        </div>
        <div
          className="project-card-image"
          style={{ backgroundImage: `url(${item.imgUrl})` }}
        />
      </Link>
      {item.tasks.length > 0 ? (
        <div className="modal-ref-container">
          <div
            className={`modal-ref-wrapper project-card-style ${theme}`}
            ref={taskLinksRef}
          >
            {item.tasks.map((task) => (
              <div
                className="task-wrapper"
                key={task.taskId}
                onClick={() =>
                  navigate(`/web_app/project/${item.id}/task/${task.taskId}`)
                }
              >
                <div className={`task-column-label ${theme}`}>
                  {task.taskColumn}
                </div>
                <div className={`task strict ${theme}`}>
                  {task.labelName ? (
                    <div className={`label ${task.labelColor} ${theme}`}>
                      {task.labelName}
                    </div>
                  ) : null}

                  <span className={`text medium pre-line ${theme}`}>
                    {task.taskName}
                  </span>
                </div>
              </div>
            ))}
          </div>
          {showNavigation ? (
            <React.Fragment>
              <div
                className="modal-ref-scroller left"
                onClick={() => {
                  scrollContainer("left");
                }}
              >
                <BsChevronLeft />
              </div>
              <div
                className="modal-ref-scroller"
                onClick={() => {
                  scrollContainer("right");
                }}
              >
                <BsChevronRight />
              </div>
            </React.Fragment>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
