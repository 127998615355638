import React from "react";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

// ICONS
import EventIcon from "@material-ui/icons/Event";
import { BsCalendarHeart } from "react-icons/bs";

export default function TaskDateReadOnly(props) {
  const { dateTo, theme } = props;

  return (
    <div className="horizontal-col min">
      <BsCalendarHeart
        className="icon standart"
        style={{ marginRight: "5px" }}
      />
      <div className={`text ${theme}`}>
        Срок готовности:&#8194;
        <strong className={`text bold ${theme}`}>
          {format(dateTo, "dd.MM.yyyy")}
        </strong>
      </div>
    </div>
  );
}
