import React, { useContext, useState, useEffect } from "react";
import SendMessage from "./SendMessage";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import AppContext from "../../../Context/AppContext";
import MessageItem from "./MessageItem";

export default function MessagesView(props) {
  const {
    taskId,
    messages,
    socket,
    showSendMessageContainer,
    addMessageToState,
    updateMessageInState,
    deleteMessageFromState,
    addEmojiInState,
    theme,
  } = props;

  const { currentUser } = useContext(AppContext);
  const { userRole } = useContext(ProjectViewContext);

  const handEmoji = ["👍", "👌", "👆", "🤝", "🙏"];
  const smileEmoji = ["😃", "🤣", "😊", "🤩", "🤪"];

  // Управляем панелью навигации
  // const [contextMenuVisibility, setContextMenuVisibility] = useState(false);
  const [contextMenuProps, setContextMenuProps] = useState({
    show: false,
    top: "0px",
    left: "0px",
  });
  // Сообщение, на которое нажал пользователь для открытия контекстного меню
  const [contextActiveMessage, setContextActiveMessage] = useState({});
  // параметры для окна-подсказки при отправки сообщения
  const [messageHelperProps, setMessageHelperProps] = useState({
    action: "", // варианты 'answer' и 'edit'§
    quoteId: 0, // id-сообщения, с которым ведется работа
    quoteText: "", // текст сообщения, на которое отвечают
    quoteName: "", // имя пользователя, на чье сообщение отвечают
  });

  // параметры для строки "печатает..."
  const [typingProps, setTypingProps] = useState({ show: false, name: "" });
  // таймер для отображения "печатает..."
  const [waitingTimer, setWaitingTimer] = useState(null);

  useEffect(() => {
    socket.on("typing_message_in_task", (data) => {
      // прерываю функцию, если сокет не успел переключиться на другую задачу
      if (data.taskId != taskId) return;

      setTypingProps({ show: true, name: data.name });

      if (waitingTimer) {
        clearTimeout(waitingTimer);
      }
      setWaitingTimer(
        setTimeout(() => {
          setTypingProps({ show: false, name: "" });
        }, 3000)
      );
    });

    // Делаю попытку отправить несинхронизированные сообщения
    let unsyncData = localStorage.getItem("unsyncData");
    unsyncData = unsyncData ? JSON.parse(unsyncData) : { messages: [] };

    if (unsyncData.messages.length == 0) return;
    let unsyncMessagesCopy = [...unsyncData.messages];

    unsyncData.messages.forEach((item, index) => {
      setTimeout(() => {
        addMessageToState(item);
        socket.emit("add_message", item, function(response) {
          if (response.status == "ok") {
            // обновляю фейковый ID на реальный
            updateMessageInState(
              item.messageId,
              response.messageId,
              "updateId"
            );

            unsyncMessagesCopy = unsyncMessagesCopy.filter(
              (cp) => cp.messageId != item.messageId
            );
            localStorage.setItem(
              "unsyncData",
              JSON.stringify({ messages: unsyncMessagesCopy })
            );
          }
        });
      }, 200);
    });
  }, []);

  function showContextMenu(e, message) {
    setContextActiveMessage(message);
    let contextMenuHeight = message.userId == currentUser.id ? 240 : 190;

    const y =
      e.clientY > document.documentElement.clientHeight - contextMenuHeight
        ? document.documentElement.clientHeight - contextMenuHeight
        : e.clientY;
    const x =
      e.clientX > document.documentElement.clientWidth - 200
        ? e.clientX - 200
        : e.clientX;

    setContextMenuProps({
      show: true,
      top: `${y}px`,
      left: `${x}px`,
    });
  }

  function hideContextMenu() {
    setContextActiveMessage({});
    let newProps = { ...contextMenuProps };
    newProps.show = false;
    setContextMenuProps(newProps);
  }

  function sendMessage(newText) {
    if (messageHelperProps.action == "") {
      addMessageToServer(newText);
    } else if (messageHelperProps.action == "answer") {
      addMessageToServer(newText, true);
    } else if ((messageHelperProps.action = "edit")) {
      editMessageOnServer(newText);
    }
  }
  const deleteMessage = (messageId) => {
    console.log("Удалить сообщение");
    const payload = {
      messageId: messageId,
    };
    deleteMessageFromState(payload);
    socket.emit("delete_message", payload);
  };

  function addMessageToServer(message, answerFlag = false) {
    const msgId = props.maxMessageId + 1;

    let tempDate = new Date();
    let payload;

    payload = {
      userId: currentUser.id,
      userName: currentUser.name,
      userPic: currentUser.user_pic,
      messageId: msgId,
      message: message,
      answerFlag: answerFlag,
      answerId: messageHelperProps.quoteId,
      answerText: messageHelperProps.quoteText,
      answerName: messageHelperProps.quoteName,
      delivered: false,
      taskId: taskId,
      dateFrom: `${tempDate.getDate()} ${tempDate.getMonth() +
        1} ${tempDate.getFullYear()} ${tempDate.getHours()} ${tempDate.getMinutes()}`,
    };

    // Шаг 1. Добавляю информацию в state с фейковым ID, чтобы сообщение быстро отразилось на экране пользователя
    addMessageToState(payload);

    // Шаг 2. Временно сохраняю сообщение в localStorage
    let unsyncData = localStorage.getItem("unsyncData");
    if (unsyncData) {
      unsyncData = JSON.parse(unsyncData);
    } else {
      unsyncData = { messages: [] };
    }
    unsyncData.messages.push(payload);
    localStorage.setItem("unsyncData", JSON.stringify(unsyncData));

    // Шаг 3. Отправляю сообщение на сервер и получаю реальный ID
    socket.emit("add_message", payload, function(response) {
      if (response.status == "ok") {
        closeMessageHelper();
        // обновляю фейковый ID на реальный
        updateMessageInState(msgId, response.messageId, "updateId");
        // удаляю сообщение из localStorage
        unsyncData.messages = unsyncData.messages.filter(
          (item) => item.messageId != msgId
        );
        localStorage.setItem("unsyncData", JSON.stringify(unsyncData));
      }
    });
  }

  function editMessageOnServer(newText) {
    let msgId = messageHelperProps.quoteId;
    socket.emit(
      "edit_message",
      { messageId: msgId, message: newText, taskId: taskId },
      function(response) {
        if (response.status == "ok") {
          updateMessageInState(msgId, newText, "updateText");
          closeMessageHelper();
        }
      }
    );
  }

  const sendTypingEventOnServer = (name) => {
    console.log("Отправляю событие печатает...");
    socket.emit("typing_message", {
      userId: currentUser.id,
      name: currentUser.name,
      taskId: taskId,
    });
  };

  function openMessageHelper(quoteId, quoteText, quoteName, type = "answer") {
    if (
      quoteId == undefined ||
      quoteText == undefined ||
      quoteName == undefined
    )
      return;
    if (type == "answer") {
      setMessageHelperProps({
        action: "answer",
        quoteId: quoteId,
        quoteText: quoteText,
        quoteName: quoteName,
      });
      // setLastquotedMessage(quoteText);
    }

    if (type == "edit") {
      setMessageHelperProps({
        action: "edit",
        quoteId: quoteId,
        quoteText: quoteText,
        quoteName: "",
      });
    }
  }

  function closeMessageHelper() {
    setMessageHelperProps({
      action: "",
      quoteId: 0,
      quoteText: "",
      quoteName: "",
    });
  }

  function richArrWithEmoji(arr, emoji) {
    if (arr == undefined) arr = [];

    let enriched = false;
    let syncWithServer = false;

    arr.forEach((element) => {
      if (element.emoji == emoji) {
        element.count++;
        let check_user_like = element.users.filter((user) =>
          user ? user.id == currentUser.id : null
        );
        if (check_user_like.length == 0) {
          element.users.push({ id: currentUser.id, pic: currentUser.user_pic });
          syncWithServer = true;
        }
        enriched = true;
      }
    });

    if (enriched == false) {
      arr.push({
        emoji: emoji,
        count: 1,
        users: [{ id: currentUser.id, pic: currentUser.user_pic }],
      });
      syncWithServer = true;
    }
    return [arr, syncWithServer];
  }

  function sendEmojiToServer(messageId, emoji) {
    let payload = {
      emoji: emoji,
      taskId: taskId,
      messageId: messageId,
    };
    socket.emit("update_task_emojies", payload);
  }

  return (
    <div className={`modal-content-container ${theme}`}>
      <div className="row">
        <div className="col min">
          <h2 className="h2">Комментарии</h2>
        </div>
      </div>
      {userRole == "admin" || userRole == "viewer" ? (
        <SendMessage
          messageHelperProps={messageHelperProps}
          userPic={currentUser.user_pic}
          typingProps={typingProps}
          theme={theme}
          showSendMessageContainer={showSendMessageContainer}
          closeMessageHelper={closeMessageHelper}
          sendMessageEvent={sendMessage}
          sendTypingEventOnServer={sendTypingEventOnServer}
        />
      ) : (
        <div className="row">
          <span className="text">
            Чтобы написать комментарий, присоеденитесь к проекту
          </span>
        </div>
      )}
      <div className="messages-container">
        {messages.map((item, key) => {
          return (
            <div
              className="message-wrapper"
              key={key}
              onClick={(event) => showContextMenu(event, item)}
            >
              <MessageItem key={item.id} item={item} theme={theme} />
            </div>
          );
        })}
        {contextMenuProps.show ? (
          <React.Fragment>
            <div
              className="context-menu-cover"
              onClick={() => hideContextMenu()}
            ></div>
            <div
              id="context-menu"
              className={`context-menu ${theme} `}
              style={{ top: contextMenuProps.top, left: contextMenuProps.left }}
              onClick={() => hideContextMenu()}
            >
              <div className="context-emoji-line">
                {handEmoji.map((item, key) => (
                  <div
                    className="context-emoji"
                    key={key}
                    onClick={() => {
                      const [emojiesList, sincWithServer] = richArrWithEmoji(
                        contextActiveMessage.emojies, // раздел messages, в котором хранятся emoji
                        item.codePointAt(0)
                      );

                      if (sincWithServer) {
                        addEmojiInState(contextActiveMessage.id, emojiesList);
                        sendEmojiToServer(
                          contextActiveMessage.id,
                          item.codePointAt(0)
                        );
                      }
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className="context-emoji-line">
                {smileEmoji.map((item, key) => (
                  <div
                    className="context-emoji"
                    key={key}
                    onClick={() => {
                      const [emojiesList, sincWithServer] = richArrWithEmoji(
                        contextActiveMessage.emojies, // раздел messages, в котором хранятся emoji
                        item.codePointAt(0)
                      );

                      if (sincWithServer) {
                        addEmojiInState(contextActiveMessage.id, emojiesList);
                        sendEmojiToServer(
                          contextActiveMessage.id,
                          item.codePointAt(0)
                        );
                      }
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div
                className="context-item"
                onClick={() => {
                  openMessageHelper(
                    contextActiveMessage.id,
                    contextActiveMessage.message,
                    contextActiveMessage.userName,
                    "answer"
                  );
                }}
              >
                Ответить на сообщение
              </div>
              <div
                className="context-item"
                onClick={() => {
                  navigator.clipboard.writeText(contextActiveMessage.message);
                }}
              >
                Скопировать
              </div>
              {currentUser.id == contextActiveMessage.userId ? (
                <React.Fragment>
                  <div
                    className="context-item"
                    onClick={() => {
                      openMessageHelper(
                        contextActiveMessage.id,
                        contextActiveMessage.message,
                        contextActiveMessage.userName,
                        "edit"
                      );
                    }}
                  >
                    Редактировать
                  </div>
                  <div
                    className="context-item"
                    onClick={() => {
                      deleteMessage(contextActiveMessage.id);
                      console.log("Удалить сообщение");
                    }}
                  >
                    Удалить
                  </div>
                </React.Fragment>
              ) : null}

              {/* <div className="context-item">Удалить</div> */}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
}
