import React, { useEffect, useRef } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

// import CodeBlock from "@tiptap/extension-code-block";
// import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
// import { lowlight } from "lowlight";
// import css from "highlight.js/lib/languages/css";
// import js from "highlight.js/lib/languages/javascript";
// import ts from "highlight.js/lib/languages/typescript";
// import html from "highlight.js/lib/languages/xml";

import "../../../styles/CodeStyle.css";

// import Prism from "prismjs";
// import "../../../styles/prism.css";

//ICONS
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatStrikethroughIcon from "@material-ui/icons/FormatStrikethrough";
import CodeIcon from "@material-ui/icons/Code";
import TitleIcon from "@material-ui/icons/Title";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import UndoIcon from "@material-ui/icons/Undo";
import InvertColorsOffIcon from "@material-ui/icons/InvertColorsOff";

// lowlight.registerLanguage("html", html);
// lowlight.registerLanguage("css", css);
// lowlight.registerLanguage("js", js);
// lowlight.registerLanguage("ts", ts);

const QUICK_SAVE_INTERVAL = 7000;

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="editor-nav-wrapper active">
      <button
        onClick={() =>
          editor
            .chain()
            .focus()
            .setParagraph()
            .run()
        }
        className={`editor-nav`}
        key="setParagraph"
      >
        <InvertColorsOffIcon />
      </button>
      <button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleHeading({ level: 2 })
            .run()
        }
        className={`editor-nav ${
          editor.isActive("heading", { level: 2 }) ? "is-active" : ""
        }`}
        key="toggleHeading"
      >
        <TitleIcon />
      </button>

      <button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        disabled={
          !editor
            .can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        className={`editor-nav ${editor.isActive("bold") ? "is-active" : ""}`}
        key="toggleBold"
      >
        <FormatBoldIcon />
      </button>

      <button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        disabled={
          !editor
            .can()
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        className={`editor-nav  ${
          editor.isActive("strike") ? "is-active" : ""
        }`}
        key="toggleStrike"
      >
        <FormatStrikethroughIcon />
      </button>

      <button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleBulletList()
            .run()
        }
        className={`editor-nav ${
          editor.isActive("bulletList") ? "is-active" : ""
        }`}
        key={"toggleBulletList"}
      >
        <FormatListBulletedIcon />
      </button>
      <button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleOrderedList()
            .run()
        }
        className={`editor-nav ${
          editor.isActive("orderedList") ? "is-active" : ""
        }`}
        key={"toggleOrderedList"}
      >
        <FormatListNumberedIcon />
      </button>
      <button
        onClick={() =>
          editor
            .chain()
            .focus()
            .toggleCodeBlock()
            .run()
        }
        className={`editor-nav ${
          editor.isActive("codeBlock") ? "is-active" : ""
        }`}
        key={"toggleCodeBlock"}
      >
        <CodeIcon />
      </button>

      <button
        onClick={() =>
          editor
            .chain()
            .focus()
            .undo()
            .run()
        }
        disabled={
          !editor
            .can()
            .chain()
            .focus()
            .undo()
            .run()
        }
        className={`editor-nav`}
        key={"undo"}
      >
        <UndoIcon />
      </button>
    </div>
  );
};

export default function TipTapEditor(props) {
  const { content } = props;

  const editableDivRef = useRef();
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        blockquote: false,
      }),

      // CodeBlockLowlight.configure({
      //   lowlight,
      // }),
    ],
    content: content,
    editable: true,
    autofocus: true,
  });

  let quickSaveInterval = null;

  useEffect(() => {
    // Принудительно делаем focus на конец документа (не уверен, что работает для iPhone)
    if (editor && editor.commands) {
      editor.commands.focus("end");
    }

    quickSaveInterval = setInterval(() => {
      if (editor !== null) {
        sendEditorData(true);
      }
    }, QUICK_SAVE_INTERVAL);
    return () => {
      try {
        clearInterval(quickSaveInterval);
      } catch (e) {}
    };
  }, [editor]);

  function sendEditorData(quick = false) {
    let content = editor.getHTML();
    props.saveEditDescriptionEvent(content, quick);
  }

  return (
    <React.Fragment>
      <div className="editor-container">
        <EditorContent
          ref={editableDivRef}
          className={`editor-wrapper pre-line active`}
          editor={editor}
        />

        <div className="col min">
          <MenuBar editor={editor} />
        </div>
      </div>

      <div className="button-wrapper">
        <button
          className="button"
          onClick={() => {
            props.undoSavingEvent();
          }}
        >
          Отмена
        </button>
        <button className="button primary" onClick={() => sendEditorData()}>
          Сохранить
        </button>
      </div>
    </React.Fragment>
  );
}
