import React, { useState, useEffect } from "react";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import axios from "axios";
import ProjectListView from "./ProjectList/ProjectListView";
import DeskView from "./Project/ProjectViews/Desk/DeskView";
import HistogramView from "./Project/ProjectViews/Histogram/HistogramView";
import MessagesView from "./Messages/MessagesView";
// import ChatView from "./Chat/ChatView";
import SettingsView from "./Project/Settings/SettingsView";
import ProfileView from "./Profile/ProfileView";
import AcceptProjectInvite from "./ProjectList/AcceptProjectInvite";
import rest from "./http/axios";
import LeftMenu from "./LeftMenu";
import BottomMenu from "./BottomMenu";
import SubscribeProjectWithLink from "./Project/SubscribeProjectWithLink";
// SOCKET
import io from "socket.io-client";
import HOST from "./AppConfig";
// CONTEXT
import BasicContext from "./Context/BasicContext";

const AppRouter = (props) => {
  const { authorized } = props;

  const [messageNtfc, setMessageNtfc] = useState(0);
  const [changeNtfc, setChangeNtfc] = useState(0);
  const [socket, setSocket] = useState();
  const [showSocketAlert, setShowSocketAlert] = useState(true);

  const theme = localStorage.getItem("theme");

  let socketReloadTimer = null;
  let socketRetries = 0;

  useEffect(() => {
    async function refreshTokenAndConnectSocket() {
      try {
        const response = await axios.get(`${HOST}/api/refresh_token`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("refresh_token"),
          },
          withCredentials: true,
        });
        let access_token = response.data.access_token;
        let headers = {
          Authorization: `Bearer ${access_token}`,
        };

        let socket = io(HOST, { extraHeaders: headers, autoConnect: false });
        socket.connect();

        setSocket(socket);
      } catch (error) {
        console.log("Error refreshing token or connecting to socket:", error);
      }
    }
    refreshTokenAndConnectSocket();

    const adoptThemeToOS = localStorage.getItem("adoptThemeToOS");

    if (adoptThemeToOS === "true") {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        localStorage.setItem("theme", "dark");
      } else {
        localStorage.setItem("theme", "");
      }
    } else {
      localStorage.setItem("theme", "");
    }

    return () => {
      if (socket) socket.close();
    };
  }, []);

  useEffect(() => {
    if (authorized === true) {
      // проверяю уведомления
      updateNotification();
      const timerId = setInterval(updateNotification, 10000);

      return () => {
        clearInterval(timerId);
      };
    }
  }, [authorized]);

  // После того, как инцииаровал soket включаю проверки
  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("Socket connected");
        if (socketReloadTimer) {
          clearInterval(socketReloadTimer);
        }
        setShowSocketAlert(false);
        socketRetries = 0;
      });

      socket.on("disconnect", () => {
        setShowSocketAlert(true);
        console.log("Socket disconnected");
        socketReloadTimer = setInterval(() => {
          socketRetries++;
          if (socketRetries > 7) {
            window.location.reload();
          }
          console.log("Socket disconnected, trying to reconnect");
          socket.connect();
        }, 2000);
      });

      // socket.on("connect_error", (error) => {
      //   console.log("Socket connection error, trying to reconnect");
      //   socket.connect();
      // });

      socket.on("error", (error) => {
        console.log("Socket error, trying to reconnect");
        setTimeout(() => {
          socket.connect();
        }, 5000);
      });
    }
  }, [socket]);

  const updateNotification = () => {
    rest.get(`/load_notifications_update`).then((response) => {
      let rsp = response.data;
      if (rsp.messageNtfc > 0 || rsp.changeNtfc > 0) {
        setMessageNtfc(rsp.messageNtfc);
        setChangeNtfc(rsp.changeNtfc);
      } else {
        setMessageNtfc(0);
        setChangeNtfc(0);
      }
    });
  };

  return (
    <BasicContext.Provider
      value={{
        socket: socket,
        showSocketAlert: showSocketAlert,
        messageNtfc: messageNtfc,
        changeNtfc: changeNtfc,
      }}
    >
      <div className="fullscreen">
        <LeftMenu />
        <div className={`workspace-container ${theme}`}>
          <Routes>
            {ProtectedRoute({
              path: ``,
              element: <ProjectListView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `profile`,
              element: <ProfileView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `messages`,
              element: <MessagesView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `project/:projectId/settings`,
              element: <SettingsView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `project/:projectId`,
              element: <DeskView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `project/:projectId/task/:taskId`,
              element: <DeskView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `project/:projectId/histogram`,
              element: <HistogramView />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `accept_project_invite/:token`,
              element: <AcceptProjectInvite />,
              authorized: authorized,
            })}

            {ProtectedRoute({
              path: `public_access/:accessType/:key`,
              element: <SubscribeProjectWithLink />,
              authorized: authorized,
            })}
          </Routes>
        </div>
        <BottomMenu theme={theme} />
      </div>
    </BasicContext.Provider>
  );
};
export default AppRouter;
