import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import HOST from "../AppConfig";
import AppContext from "../Context/AppContext";
import rest from "../http/axios";

export default function TokenLogin(props) {
  const { loginUserEvent } = useContext(AppContext);

  const [authMessage, setAuthMessage] = useState(
    "Идет проверка ссылки и авторизация..."
  );

  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    loginWithToken();
    // console.log(token);
  });

  function loginWithToken() {
    axios
      .post(
        `${HOST}/api/login_with_token`,
        { token: token },
        { withCredentials: true }
      )
      .then(async (response) => {
        let resp = response.data;
        localStorage.setItem("access_token", resp.accessToken);
        localStorage.setItem("refresh_token", resp.refreshToken);

        await rest.get("/user_info").then((response) => {
          resp = response.data;

          let profileObj = {
            id: resp.id,
            name: resp.firstName,
            second_name: resp.secondName,
            user_pic: resp.userPic,
            mob_num: resp.mobNum,
            email: resp.email,
            registration_date: resp.registrationDate,
            web_notification: resp.webNotification,
            web_notification_token: "",
            mail_notification: resp.mailNotification,
          };

          if (resp) {
            loginUserEvent(profileObj);
            navigate("/");
          }
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 400) {
          console.log(error.response.data);
          setAuthMessage(error.response.data);
        } else {
          console.log("LOGIN ERROR: ", error);
        }
      });
  }
  return <div>{authMessage}</div>;
}
