import React, { useState } from "react";

// ELEMENTS
import Checkbox from "@material-ui/core/Checkbox";

export default function ActiveFilter(props) {
  const { modifyFilterSet } = props;

  const [checkboxStatus, setCheckboxStatus] = useState(false);

  const handleChange = (event) => {
    let status = event.target.checked;
    setCheckboxStatus(status);
    modifyFilterSet("set", "active", status);
  };

  return (
    <div className="select-item">
      <div className="col">
        <span>С непрочитанными изменениями</span>
      </div>
      <div className="col min">
        <Checkbox
          checked={checkboxStatus}
          onChange={handleChange}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>
    </div>
  );
}
