import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import rest from "../../http/axios";
import TopMenu from "./TopMenu";
import ProjectName from "./ProjectName/ProjectName";
import ProjectNameReadOnly from "./ProjectName/ProjectNameReadOnly";
import ProjectImage from "./ProjectImage/ProjectImage";
import Users from "./Users/Users";
import ExitProject from "./ExitProject";

// Элементы
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// ICONS
import ProjectImageReadOnly from "./ProjectImage/ProjectImageReadOnly";
import { BiCopy } from "react-icons/bi";

export default function SettingsView(props) {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [projectName, setProjectName] = useState("");
  const [projectPic, setProjectPic] = useState("");
  const [projectRole, setProjectRole] = useState("");
  // const [currency, setCurrency] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [snackbar, setSnackbar] = useState({
    status: false,
    message: "",
    severity: "success", // green
  });
  const [showTaskModalAfterCreation, setShowTaskModalAfterCreation] = useState(
    true
  );
  // ключ доступа к проекту по внешней ссылке на роль пока что Админ
  const [publicUserKey, setPublicUserKey] = useState({
    status: false,
    key: "",
  });
  // ключ доступа к проекту по внешней ссылке на роль "только чтение"
  const [publicReadonlyKey, setPublicReadonlyKey] = useState({
    status: false,
    key: "",
  });

  useEffect(() => {
    loadProjectProfile();
  }, []);

  const loadProjectProfile = () => {
    rest.get(`/load_project_profile/${projectId}`).then((response) => {
      let rsp = response.data;
      setProjectName(rsp.projectName);
      setProjectPic(rsp.projectPic);
      setRegistrationDate(rsp.registrationDate);
      // setCurrency(rsp.currency);
      setProjectRole(rsp.projectRole);
      setShowTaskModalAfterCreation(rsp.showTaskModalAfterCreation);
      setPublicUserKey(rsp.publicAccessUserKey);
      setPublicReadonlyKey(rsp.publicAccessReadonlyKey);
    });
  };

  const adoptProjectName = (newProjectName) => {
    setProjectName(newProjectName);

    rest
      .post(`/update_project_info`, {
        projectId: projectId,
        newProjectName: newProjectName,
      })
      .then((resp) => {
        showSnackBar(resp.data);
      });
  };

  const adoptProjectPic = (projectPic, message) => {
    setProjectPic(projectPic);
    showSnackBar(message);
  };

  const showSnackBar = (message, severity = "success") => {
    setSnackbar({ status: true, message: message, severity: severity });
  };

  const hideSnackBar = () => {
    setSnackbar({ status: false, message: "" });
  };

  const updateProjectOptions = (attr, val) => {
    if (attr == "showTaskModalAfterCreation") {
      setShowTaskModalAfterCreation(val);

      rest
        .post("/update_project_settings", {
          attr: attr,
          val: val,
          projectId: projectId,
        })
        .then((response) => {
          console.log(response.data);
        });
    }
  };

  const switchPublicLink = (accessType, status) => {
    if (status == true) {
      rest
        .post("/modify_public_link", {
          projectId: projectId,
          accessType: accessType,
          action: "create",
        })
        .then((response) => {
          let key = response.data.projectKey;
          let keyData = { status: status, key: key };

          if (accessType == "user") {
            setPublicUserKey(keyData);
          } else {
            setPublicReadonlyKey(keyData);
          }
        });
    } else {
      let keyData = { status: false, key: "" };
      rest
        .post("/modify_public_link", {
          projectId: projectId,
          accessType: accessType,
          action: "delete",
        })
        .then(() => {
          if (accessType == "user") {
            setPublicUserKey(keyData);
          } else {
            setPublicReadonlyKey(keyData);
          }
        });
    }
  };

  return (
    <div className="absolute-container">
      <TopMenu projectId={projectId} />
      <div className="settings-container">
        <div className="col scrollable">
          {projectRole === "admin" ? (
            <React.Fragment>
              <ProjectName
                projectName={projectName}
                adoptProjectNameEvent={adoptProjectName}
              />
              <div className="content-wrapper">
                <span className="text grey">Ссылки на проект</span>
                <div className="row">
                  <div className="col">
                    <button
                      className="button sky outline"
                      onClick={() => {
                        navigate(`/web_app/project/${projectId}`);
                      }}
                    >
                      Доска
                    </button>
                  </div>
                  <div className="col">
                    <button
                      className="button warning outline"
                      onClick={() => {
                        navigate(`/web_app/project/${projectId}/histogram`);
                      }}
                    >
                      Аналитика
                    </button>
                  </div>
                  <div className="col"></div>
                </div>
              </div>
              <ProjectImage
                projectPic={projectPic}
                projectId={projectId}
                adoptProjectPicEvent={adoptProjectPic}
              />
              <div className="content-wrapper">
                <h1 className="h1">Настройки проекта </h1>
                <div className="row">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showTaskModalAfterCreation}
                        onChange={(event) =>
                          updateProjectOptions(
                            "showTaskModalAfterCreation",
                            event.target.checked
                          )
                        }
                        color="primary"
                      />
                    }
                    label="Открывать окно задачи сразу после ее создания"
                  />
                </div>
              </div>
              {projectRole === "admin" ? (
                <div className="content-wrapper">
                  <h1 className="h1">Доступность проекта </h1>
                  <div className="row">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={publicUserKey.status}
                          onChange={(event) =>
                            switchPublicLink("user", event.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label="Открыть доступ по ссылке с ролью Администратор"
                    />
                  </div>
                  {publicUserKey.status ? (
                    <div className="row">
                      <div className="col">
                        <input
                          value={
                            window.location.origin +
                            "/web_app/public_access/user/" +
                            publicUserKey.key
                          }
                          className="input w-input"
                          readOnly
                        />
                      </div>
                      <div className="col min">
                        <button
                          className="button w-button"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              window.location.origin +
                                "/web_app/public_access/user/" +
                                publicUserKey.key
                            );
                            showSnackBar("Скопировано в буфер обмена");
                          }}
                        >
                          <BiCopy style={{ marginRight: "5px" }} /> Копировать
                          ссылку
                        </button>
                      </div>
                    </div>
                  ) : null}

                  <div className="row">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={publicReadonlyKey.status}
                          onChange={(event) =>
                            switchPublicLink("readonly", event.target.checked)
                          }
                          color="primary"
                        />
                      }
                      label="Открыть доступ по ссылке с ролью Только чтение"
                    />
                  </div>

                  {publicReadonlyKey.status ? (
                    <div className="row">
                      <div className="col">
                        <input
                          value={
                            window.location.origin +
                            "/web_app/public_access/readonly/" +
                            publicReadonlyKey.key
                          }
                          className="input w-input"
                          readOnly
                        />
                      </div>
                      <div className="col min">
                        <button
                          className="w-button button"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              window.location.origin +
                                "/web_app/public_access/readonly/" +
                                publicReadonlyKey.key
                            );
                            showSnackBar("Скопировано в буфер обмена");
                          }}
                        >
                          <BiCopy style={{ marginRight: "5px" }} /> Копировать
                          ссылку
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ProjectNameReadOnly projectName={projectName} />
              <ProjectImageReadOnly projectPic={projectPic} />
            </React.Fragment>
          )}
          <div className="content-wrapper">
            <ExitProject projectId={projectId} />
          </div>
        </div>
        <div className="col scrollable">
          {projectRole === "admin" ? (
            <Users projectId={projectId} showSnackBar={showSnackBar} />
          ) : (
            <p> тут будут пользователи</p>
          )}
        </div>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbar.status}
          autoHideDuration={4000}
          onClose={hideSnackBar}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            {...props}
            severity={snackbar.severity}
            onClose={hideSnackBar}
          >
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </div>
    </div>
  );
}
