import React, { useState, useContext } from "react";
import { format, addDays, differenceInDays, parse } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import ProjectViewContext from "../../../Context/ProjectViewContext";
// ICONS
import { BsCalendarHeart } from "react-icons/bs";

//UI
import Slide from "@material-ui/core/Slide";

export default function TaskDate(props) {
  const { dateTo, columnId, updateTaskDate, theme } = props;
  const { projectId, taskId, socket, adoptTaskDateInProject } = useContext(
    ProjectViewContext
  );

  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState();

  const calcSelectedDateTo = (startDate, daysNum) => {
    let newDate = addDays(startDate, daysNum);
    setSelectedDate(newDate);
  };

  const openModal = () => {
    setShowModal(true);
    setSelectedDate(dateTo);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const saveSelectedDateTo = () => {
    let dateData = {
      projectId: projectId,
      columnId: columnId,
      taskId: taskId,
      dateTo: format(selectedDate, "yyyy-MM-dd"),
      daysTo: differenceInDays(selectedDate, new Date()) + 1, // +1 потому что differenceInDays считает дни с 0
    };
    socket.emit("update_task_date_to", dateData);

    updateTaskDate(dateData);
    adoptTaskDateInProject(dateData);
    closeModal();
  };

  return (
    <div className="horizontal-col min">
      <BsCalendarHeart
        className="icon standart"
        style={{ marginRight: "5px" }}
      />
      <div>
        <span className={`text ${theme}`}>Срок готовности:&#8194;</span>
        <strong
          className={`text dark-blue active ${theme}`}
          onClick={openModal}
        >
          {format(dateTo, "dd.MM.yyyy")}
        </strong>
      </div>
      {/* МОДАЛЬНОЕ ОКНО */}
      {showModal ? (
        <React.Fragment>
          <div className="modal-cover active" onClick={closeModal}></div>
          <Slide direction="up" in={showModal} mountOnEnter unmountOnExit>
            <div className="modal-wrapper">
              <div className="simple-modal">
                <div className="modal-header">
                  <h1 className="h1">Срок готовности</h1>
                </div>
                <div className="modal-content">
                  <form>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => calcSelectedDateTo(date, 0)}
                      dateFormat="dd.MM.yyyy"
                      className="w-input input"
                      locale={ru}
                      calendarStartDay={1}
                    />
                  </form>
                  <div className="wr">
                    <div className="col">
                      <button
                        className="button "
                        onClick={() => calcSelectedDateTo(new Date(), 1)}
                      >
                        Завтра
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="button "
                        onClick={() => calcSelectedDateTo(new Date(), 7)}
                      >
                        Через неделю
                      </button>
                    </div>
                  </div>
                  <div className="wr">
                    <div className="col">
                      <button
                        className="button "
                        onClick={() => calcSelectedDateTo(new Date(), 14)}
                      >
                        Через 2 недели
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="button "
                        onClick={() => calcSelectedDateTo(new Date(), 30)}
                      >
                        Через месяц
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className=" button" onClick={closeModal}>
                    Закрыть
                  </button>
                  <button
                    className=" button primary"
                    onClick={saveSelectedDateTo}
                  >
                    Сохранить
                  </button>
                </div>
              </div>
            </div>
          </Slide>
        </React.Fragment>
      ) : null}
    </div>
  );
}
