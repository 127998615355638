import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import rest from "../http/axios";
import AppContext from "../Context/AppContext";
import UserImage from "./UserImage";

// Компоненты
import InputField from "../Components/InputField";

// Иконки
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";

// Элементы
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function ProfileView(props) {
  const { currentUser, updateUserProfileEvent } = useContext(AppContext);
  const [username, setUsername] = useState(currentUser.name);
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  // const [email, setEmail] = useState(currentUser.email);
  const [mobNum, setMobNum] = useState(currentUser.mob_num);
  const [loadValue, setLoadValue] = useState(0);
  const [snackbar, setSnackbar] = useState({ status: false, message: "" });
  const [adoptThemeToOS, setAdoptThemeToOS] = useState(
    localStorage.getItem("adoptThemeToOS") === "true" ? true : false
  );

  const theme = localStorage.getItem("theme");
  const navigate = useNavigate();
  const [mailNotification, setMailNotification] = useState(
    currentUser.mail_notification
  );

  function handleMailNotificationEvent(event) {
    let status = event.target.checked;
    rest
      .post("/update_notification_settings", {
        notificationChannel: "mail",
        notificationStatus: status,
      })
      .then((resp) => {
        updateUserProfileEvent(false, false, false, false, status, false);
        setMailNotification(status);
      });
  }

  function saveProfileData(event) {
    event.preventDefault();
    rest
      .post("/update_user_profile", {
        username: username,
        // email: email,
        mobNum: mobNum,
      })
      .then((resp) => {
        showSnackBar(resp.data.message);
        updateUserProfileEvent(username, mobNum);
      })
      .catch((error) => {
        if (error) {
          console.log(error);
          // console.log("PROFILE ERROR: ", error.response);
        }
      });
  }

  function saveNewPassword(event) {
    event.preventDefault();
    if (pass1 == pass2) {
      rest
        .post("/update_password", {
          password: pass1,
        })
        .then((resp) => {
          showSnackBar(resp.data);
        })
        .catch((error) => {
          if (error) {
            console.log(error);
            // console.log("PROFILE ERROR: ", error.response);
          }
        });
    }
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function showSnackBar(message) {
    setSnackbar({ status: true, message: message });
  }

  function hideSnackBar() {
    setSnackbar({ status: false, message: "" });
  }

  return (
    <div className={`settings-container ${theme}`}>
      <div className="col scrollable">
        <div className={`content-wrapper ${theme}`}>
          <h1 className="h1">Профиль ({currentUser.email})</h1>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar["status"]}
            autoHideDuration={4000}
            onClose={hideSnackBar}
          >
            <Alert severity="success" onClose={hideSnackBar}>
              {snackbar["message"]}
            </Alert>
          </Snackbar>

          <div className="w-form">
            <form className="vertical-form" onSubmit={saveProfileData}>
              <InputField
                label="Имя пользователя"
                defaultValue={username}
                iconObj=<PermIdentityIcon />
                theme={theme}
                onChangeEvent={setUsername}
              />
              <InputField
                label="Номер телефона"
                defaultValue={mobNum}
                iconObj=<PhoneEnabledIcon />
                theme={theme}
                onChangeEvent={setMobNum}
              />

              <div className="button-wrapper">
                <input
                  type="submit"
                  value="Сохранить"
                  className="button primary "
                />
              </div>
            </form>
          </div>
        </div>
        <div className={`content-wrapper ${theme}`}>
          <UserImage showSnackBar={showSnackBar} />
        </div>
      </div>
      <div className="col scrollable">
        <div className={`content-wrapper ${theme}`}>
          <h1 className="h1">Изменение пароля</h1>
          <div className="w-form">
            <form className="vertical-form" onSubmit={saveNewPassword}>
              <InputField
                id="password"
                label="Пароль"
                defaultValue={pass1}
                iconObj=<LockOpenIcon />
                theme={theme}
                onChangeEvent={setPass1}
              />
              <InputField
                id="password_repeat"
                label="Повторите ввод пароля"
                defaultValue={pass2}
                iconObj=<LockOpenIcon />
                theme={theme}
                onChangeEvent={setPass2}
              />

              <div className="button-wrapper">
                <input
                  type="submit"
                  value="Изменить пароль"
                  className="button primary "
                />
              </div>
            </form>
          </div>
        </div>
        <div className={`content-wrapper ${theme}`}>
          <h1 className="h1">Настройки уведомлений</h1>
          <div className="row">
            <FormControlLabel
              control={
                <Switch
                  checked={mailNotification}
                  onChange={(event) => handleMailNotificationEvent(event)}
                  color="primary"
                />
              }
              label="Получать уведомления по e-mail"
            />
          </div>
        </div>
        <div className={`content-wrapper ${theme}`}>
          <h1 className="h1">Оформление</h1>
          <div className="row">
            <FormControlLabel
              control={
                <Switch
                  checked={adoptThemeToOS}
                  onChange={(event) => {
                    setAdoptThemeToOS(event.target.checked);
                    localStorage.setItem(
                      "adoptThemeToOS",
                      event.target.checked
                    );
                    window.location.reload();
                  }}
                  color="primary"
                />
              }
              label="Менять тему оформления на светлую/темную в зависимости от настроек операционной системы"
            />
          </div>
        </div>

        <div
          className={`content-wrapper ${theme}`}
          style={{ marginBottom: "50px" }}
        >
          <h1 className="h1">Выйти из аккаунта</h1>

          <button
            className="button danger outline"
            style={{ width: "200px" }}
            onClick={() => {
              navigate("/logout");
            }}
          >
            Выйти
          </button>
        </div>
      </div>
    </div>
  );
}
