import React from "react";
import { BsCheck2Circle } from "react-icons/bs";

export default function ColumnStatusView(props) {
  const {
    columnSuccessStatus,
    columnName,
    changeTaskColumnSuccessStatus,
  } = props;

  return (
    <div className="row">
      <div className="col">
        <span className="text grey small">
          Работы над задачей в колонке <b>{columnName}</b> завершены:
        </span>
      </div>
      <div className="col-min">
        {columnSuccessStatus ? (
          <button
            className="button done outline"
            onClick={() => {
              changeTaskColumnSuccessStatus(false);
            }}
          >
            <BsCheck2Circle
              className="filled-icon active"
              style={{ marginRight: "5px" }}
            />
            Работы завершены
          </button>
        ) : (
          <button
            className="button grey outline"
            onClick={() => {
              changeTaskColumnSuccessStatus(true);
            }}
          >
            <BsCheck2Circle
              className="filled-icon"
              style={{ marginRight: "5px" }}
            />
            Подтвердить
          </button>
        )}
      </div>

      {/* {status ? (
        <div
          className="horizontal-col min"
          onClick={() => {
            setStatus(!status);
          }}
        >
          <BsCheck2Circle className="filled-icon active" />
        </div>
      ) : (
        <div
          className="horizontal-col min"
          onClick={() => {
            setStatus(!status);
          }}
        >
          <BsCheck2Circle className="filled-icon" />
        </div>
      )} */}
    </div>
  );
}
