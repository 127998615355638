import React, { useState, useRef, useEffect, useContext } from "react";
import ProjectViewContext from "../../../Context/ProjectViewContext";
import BasicContext from "../../../Context/BasicContext";

export default function TaskNameView(props) {
  const {
    columnId,
    updateTaskName,
    setShowSendMessageContainerEvent,
    name,
    theme,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [taskName, setTaskName] = useState(props.name);
  const [prevTaskName, setPrevTaskName] = useState("");

  const { projectId, taskId, adoptTaskNameInProject } = useContext(
    ProjectViewContext
  );
  const { socket } = useContext(BasicContext);
  const textareaRef = useRef();

  function showEditMode() {
    setEditMode(true);
    setPrevTaskName(taskName);
    setShowSendMessageContainerEvent(false);
  }

  function closeEditMode(event) {
    setEditMode(false);

    if (taskName !== prevTaskName && taskName !== "") {
      let nameData = {
        projectId: projectId,
        columnId: columnId,
        taskId: taskId,
        taskName: taskName,
      };

      updateTaskName(nameData);
      adoptTaskNameInProject(nameData);
      socket.emit("update_task_name", nameData);
    }
    setShowSendMessageContainerEvent(true);
  }

  useEffect(() => {
    if (editMode === true) {
      textareaRef.current.focus();
    }
  });

  return (
    <div className="title-wrapper">
      {editMode ? (
        <div className="row">
          <div className="col">
            <textarea
              ref={textareaRef}
              className={`textarea w-input heading ${theme}`}
              onChange={(event) => setTaskName(event.target.value)}
              value={taskName}
              onBlur={closeEditMode}
            />
            <div className="button-wrapper">
              <button className=" button primary" onClick={closeEditMode}>
                Сохранить
              </button>
            </div>
          </div>
        </div>
      ) : (
        <h1 className={`active pre-line ${theme}`} onClick={showEditMode}>
          {name}
        </h1>
      )}
    </div>
  );
}
